import React from "react"
import { BillingProductName } from "@digits-graphql/frontend/graphql-bearer"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgDigitsLogoNoBackground } from "@digits-shared/components/SVGIcons/digits/DigitsLogoNoBackground.svg"
import { SvgAlertTriangleSolid } from "@digits-shared/components/SVGIcons/solid/AlertTriangleSolid.svg"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import { DigitsLinkButton } from "@digits-shared/DesignSystem/LinkButton"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import { JWTPermissionFlag } from "@digits-shared/session/jwt/jwtPermissions"
import colors from "@digits-shared/themes/colors"
import fonts, { LabelText } from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import {
  AffiliateOrganizationBillingPlanProvider,
  useAffiliateOrganizationBillingPlan,
} from "src/frontend/components/OS/Applications/Settings/AffiliateOrganizationBillingPlanContext"
import { useEnterpriseReachOutModalContext } from "src/frontend/components/OS/Applications/Settings/EnterpriseReachOutModalContext"
import { useHasBillablePlan } from "src/frontend/components/OS/Applications/Settings/hooks/useHasBillablePlan"
import { useNextPlanName } from "src/frontend/components/OS/Applications/Settings/Plans/useNextPlanName"
import { AddClientModal } from "src/frontend/components/OS/Header/AddClient/AddClientModal"
import { useAddClientModal } from "src/frontend/components/OS/Header/AddClient/useAddClientModal"
import {
  RequiresCreate,
  useHasPermission,
} from "src/frontend/components/Shared/Permissions/Requires"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
} from "src/frontend/session/permissionModule"

const CautionIcon = styled(SvgAlertTriangleSolid)`
  width: 14px;
  height: 14px;
  ${svgIconStyles(colors.orange)};
`

const DigitsLogo = styled(SvgDigitsLogoNoBackground)<{ color?: string }>`
  height: 20px;
  width: 20px;
  border-radius: 20px;
  margin-bottom: 1px;
  ${({ color }) => svgIconStyles(color || colors.secondary)};
`

const Container = styled.div`
  padding: 10px 15px;
  width: 100%;
  margin-bottom: 24px;
`

const PlanInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const PlanName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-weight: ${fonts.weight.heavy};
  font-size: 12px;
  line-height: 12px;
`

const PlanDescription = styled(LabelText)`
  opacity: 0.8;
`

export const PlanExceeded = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-size: 9px;
  line-height: 12px;
  color: ${colors.error};
  margin-bottom: 3px;
`

const ClientCountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
  width: 100%;
  margin-bottom: 15px;
`

const ClientCountStyled = styled.div<{ exceeded: boolean }>`
  font-weight: ${fonts.weight.black};
  font-size: 14px;

  ${({ exceeded }) =>
    exceeded &&
    css`
      color: ${colors.error};
    `}
`

const ClientCountText = styled.div`
  font-size: 13px;
  line-height: 14px;
  text-transform: uppercase;
`

const ExceedText = styled.div`
  margin-top: 10px;
  font-weight: ${fonts.weight.normal};
  font-size: 10px;
  opacity: 0.8;
`

const NonAdminExceedText = styled.div`
  margin-top: 10px;
  font-weight: ${fonts.weight.normal};
  font-size: 12px;
  opacity: 0.8;
`

const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`

const PlanDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`

const ProgressContainer = styled.div`
  width: 100%;
  height: 10px;
  background-color: white;
  border-radius: 30px;
  margin-bottom: 11px;
`

const ProgressCompleted = styled.div<{ percent: number }>`
  width: ${({ percent }) => Math.min(percent, 100)}%;
  height: 100%;
  border-radius: 30px;
  ${({ percent }) =>
    percent >= 100
      ? css`
          background: linear-gradient(360deg, #eec942 -8.11%, #f58b74 100%);
          box-shadow: 0px 0px 10px rgba(232, 199, 80, 0.5);
        `
      : css`
          background: linear-gradient(360deg, #74f5bc -8.11%, #74d6f5 100%);
          box-shadow: 0px 0px 10px rgba(113, 245, 187, 0.5);
        `};
`

/*
  INTERFACES
*/

interface Props {
  className?: string
  logoColor?: string
  variant?: "secondary-dark" | "secondary-light"
}

/*
  COMPONENTS
*/

export const AccountantAddClient: React.FC<Props> = ({ className, logoColor, variant }) => (
  <AffiliateOrganizationBillingPlanProvider>
    <PlanAddClient className={className} logoColor={logoColor} variant={variant} />
  </AffiliateOrganizationBillingPlanProvider>
)

const PlanAddClient: React.FC<Props> = ({ className, logoColor, variant }) => {
  const hasCreateLegalEntityPermission = useHasPermission({
    source: FrontendPermissionSource.Organization,
    module: FrontendPermissionModule.LegalEntities,
    flag: JWTPermissionFlag.Create,
  })
  const hasReadBillingPermission = useHasPermission({
    source: FrontendPermissionSource.Organization,
    module: FrontendPermissionModule.Billing,
    flag: JWTPermissionFlag.Read,
  })

  const { isBillablePlan } = useHasBillablePlan()
  if (hasCreateLegalEntityPermission && hasReadBillingPermission && isBillablePlan) {
    return <BillablePlanAddClient className={className} logoColor={logoColor} variant={variant} />
  }

  return <AddClient />
}

const BillablePlanAddClient: React.FC<Props> = ({ className, logoColor, variant }) => {
  const { loading } = useAffiliateOrganizationBillingPlan()

  if (loading) {
    return <LoadingBlock height="25px" width="100%" />
  }

  return (
    <RequiresCreate
      source={FrontendPermissionSource.Organization}
      module={FrontendPermissionModule.LegalEntities}
    >
      <Container className={className}>
        <PlanDetails logoColor={logoColor} />
        <ProgressBar />
        <ClientCount />
        <CTAButtons variant={variant} />
      </Container>
    </RequiresCreate>
  )
}

const PlanDetails: React.FC<Pick<Props, "logoColor">> = ({ logoColor }) => {
  const { planName, planDetails, planMaxed } = useAffiliateOrganizationBillingPlan()

  return (
    <PlanDetailsContainer>
      <DigitsLogo color={logoColor} />
      <PlanInfo>
        <PlanName>{planName} Plan</PlanName>
        <PlanDescription>{planMaxed ? "Maximum Clients Reached" : planDetails}</PlanDescription>
      </PlanInfo>
    </PlanDetailsContainer>
  )
}

export const ProgressBar: React.FC = () => {
  const { planMaxLimit, clientCount } = useAffiliateOrganizationBillingPlan()
  const percent = (clientCount / planMaxLimit) * 100

  return (
    <ProgressContainer>
      <ProgressCompleted percent={percent} />
    </ProgressContainer>
  )
}

export const ClientCount: React.FC = () => {
  const { planMaxLimit, clientCount, planExceeded } = useAffiliateOrganizationBillingPlan()

  const count = `${clientCount} of ${planMaxLimit}`

  return (
    <>
      {planExceeded && (
        <PlanExceeded>
          <CautionIcon />
          Limit exceeded. Please upgrade.
        </PlanExceeded>
      )}
      <ClientCountContainer>
        <ClientCountStyled exceeded={planExceeded}>{count}</ClientCountStyled>
        <ClientCountText>Clients Linked</ClientCountText>
      </ClientCountContainer>
    </>
  )
}

const CTAButtons: React.FC<Pick<Props, "variant">> = ({ variant = "secondary-dark" }) => {
  const { history } = useRouter()
  const generatePath = useFrontendPathGenerator()
  const { planName, planMaxed } = useAffiliateOrganizationBillingPlan()
  const { currentOrganization } = useSession<FrontendSession>()
  const nextPlanName = useNextPlanName()
  const { onAddClient, hideModal, isModalActive } = useAddClientModal()

  const hasBillingPermission = useHasPermission({
    source: FrontendPermissionSource.Organization,
    module: FrontendPermissionModule.Billing,
    flag: JWTPermissionFlag.Update,
  })
  // only admins should be able view the upgrade now btn
  const isOrgAdmin = Boolean(
    currentOrganization?.activeAffiliations?.length && hasBillingPermission
  )

  const { openModal: openEnterpriseModal } = useEnterpriseReachOutModalContext()

  const showEnterpriseModal = planMaxed && planName === BillingProductName.Premium

  const onClickUpgrade = React.useCallback(() => {
    if (showEnterpriseModal) {
      // opens enterprise reachout modal
      openEnterpriseModal()
    } else {
      history.push(
        generatePath(routes.organizationBillingCheckout, {
          orgSlug: currentOrganization?.slug,
          plan: nextPlanName,
        })
      )
    }
  }, [
    currentOrganization?.slug,
    generatePath,
    history,
    nextPlanName,
    openEnterpriseModal,
    showEnterpriseModal,
  ])

  // no more clients can be added until billing plan is upgraded
  if (planMaxed) {
    return (
      <CTAContainer>
        {(isOrgAdmin && (
          <>
            <ExceedText>Upgrade your plan to add more clients.</ExceedText>
            <DigitsButton $fullWidth onClick={onClickUpgrade}>
              Upgrade Now
            </DigitsButton>
          </>
        )) || (
          <NonAdminExceedText>
            Unlock the power of the Digits platform across all of your clients. Contact your Admin
            to upgrade your plan to add more clients.
          </NonAdminExceedText>
        )}
      </CTAContainer>
    )
  }

  return (
    <CTAContainer>
      <DigitsButton $fullWidth onClick={onAddClient}>
        Add Client
      </DigitsButton>
      {isOrgAdmin && (
        <DigitsLinkButton
          $fullWidth
          $variant={variant}
          to={routes.organizationBilling.generate({ orgSlug: currentOrganization?.slug })}
        >
          See Plan
        </DigitsLinkButton>
      )}
      {isModalActive && <AddClientModal onDone={hideModal} onClose={hideModal} />}
    </CTAContainer>
  )
}

const AddClient: React.FC<Pick<Props, "className">> = ({ className }) => {
  const { onAddClient, hideModal, isModalActive } = useAddClientModal()
  return (
    <RequiresCreate
      source={FrontendPermissionSource.Organization}
      module={FrontendPermissionModule.LegalEntities}
    >
      <Container className={className}>
        <CTAContainer>
          <DigitsButton $fullWidth onClick={onAddClient}>
            Add Client
          </DigitsButton>
        </CTAContainer>
      </Container>
      {isModalActive && <AddClientModal onDone={hideModal} onClose={hideModal} />}
    </RequiresCreate>
  )
}
