import * as React from "react"
import { type EntityTransaction } from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import styled, { css } from "styled-components"
import { TransactionsTable } from "src/frontend/components/OS/Applications/Reports/Report/Components/Transactions/TransactionsList"
import type FrontendSession from "src/frontend/session"
import { TransactionsTableContextProvider } from "src/shared/components/Transactions/TransactionsTableContext"

/*
  STYLES
*/

const TopTransactionsTable = styled(TransactionsTable)<{ minHeight?: number }>`
  padding: 15px 0;
  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight}px;
    `}
`

/*
  INTERFACES
*/

interface TopTransactionsProps {
  transactions: EntityTransaction[]
  className?: string
  minHeight?: number
}

/*
  COMPONENTS
*/

export const TopTransactions: React.FC<TopTransactionsProps> = ({
  transactions,
  minHeight,
  className,
}) => {
  const { currentLegalEntityId: legalEntityId } = useSession<FrontendSession>()
  return (
    <TransactionsTableContextProvider value={{ legalEntityId }}>
      <TopTransactionsTable
        className={className}
        transactions={transactions}
        showHeader={false}
        minHeight={minHeight}
      />
    </TransactionsTableContextProvider>
  )
}
