import * as React from "react"
import { type StatementDeltas } from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { ChartPeriod } from "src/frontend/components/OS/Applications/Reports/Report/Components/Shared/DetailsPopOver/ChartPeriod"
import { ChartContainer } from "src/frontend/components/OS/Applications/Reports/Report/Components/Shared/DetailsPopOver/DetailsPopUp"
import useRowSelectionContext from "src/frontend/components/OS/Applications/Reports/Report/Components/Statements/RowSelectionContext"
import { ParentSizedTimeseriesBarChart } from "src/frontend/components/Shared/Layout/Components/Charts/TimeseriesBarChart"
import { ParentSizedTimeseriesLineChart } from "src/frontend/components/Shared/Layout/Components/Charts/TimeseriesLineChart"
import {
  type Timeseries,
  type TimeseriesValue,
} from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"

/*
  INTERFACES
*/

interface ChartProps {
  amountOverTime?: Timeseries | null
  deltas?: StatementDeltas
  isBalance: boolean
  className?: string
  skipAnimations?: boolean
}

/*
  COMPONENTS
*/

export const OverTimeChart: React.FC<ChartProps> = ({
  amountOverTime,
  deltas,
  isBalance,
  className,
  skipAnimations,
}) => {
  const [selectedPrevious, setSelectedPrevious] = React.useState<TimeseriesValue>()

  const { selectedValue } = useRowSelectionContext()
  React.useEffect(() => {
    if (!selectedValue || !amountOverTime?.values?.length) {
      return setSelectedPrevious(undefined)
    }

    const currentPeriod = amountOverTime.values[amountOverTime.values.length - 1]?.period
    const selectedPeriod = selectedValue.value?.period
    if (dateTimeHelper.arePeriodsEqual(currentPeriod, selectedPeriod)) {
      return setSelectedPrevious(undefined)
    }

    if (
      currentPeriod &&
      dateTimeHelper.isPeriodYearToDate(currentPeriod) !==
        dateTimeHelper.isPeriodYearToDate(selectedPeriod)
    ) {
      return setSelectedPrevious(undefined)
    }

    return setSelectedPrevious(selectedValue.value)
  }, [amountOverTime?.values, selectedValue])

  if (!amountOverTime?.values?.length) return null
  const { values } = amountOverTime
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const current = values[values.length - 1]!

  return (
    <ChartContainer className={className}>
      {isBalance ? (
        <ParentSizedTimeseriesLineChart
          skipAnimations={skipAnimations}
          preselectedValue={selectedPrevious}
          timeseries={values}
          onMouseOver={setSelectedPrevious}
          onMouseOut={setSelectedPrevious}
          hideGrid
        />
      ) : (
        <ParentSizedTimeseriesBarChart
          skipAnimations={skipAnimations}
          timeseries={values}
          preselectedValue={selectedPrevious}
          onMouseOver={setSelectedPrevious}
          onMouseOut={setSelectedPrevious}
          hideGrid
        />
      )}
      <ChartPeriod current={current} previous={selectedPrevious} deltas={deltas} />
    </ChartContainer>
  )
}
