import * as React from "react"
import { DayPickerLocation } from "@digits-shared/components/UI/Elements/Inputs/DateInput"
import { KPIOptions } from "src/frontend/components/OS/Applications/Reports/Report/Options/KPIOptions"
import { ComponentHeaderTitle } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import {
  TileCard,
  TileCardHeading,
} from "src/frontend/components/Shared/Layout/Components/Tiles/TileCard"
import { useComponentTitle } from "src/frontend/components/Shared/Layout/Components/useComponentTitle"
import { type ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import {
  type MatchedComponent,
  type SizingProps,
} from "src/frontend/components/Shared/Layout/types"
import { usePortalDispatch } from "src/frontend/components/Shared/Portals/State/portalStore"
import { useIsEditLayoutActive } from "src/frontend/components/Shared/Portals/State/useIsEditLayoutActive"

/*
  STYLES
*/

/*
  INTERFACES
*/

interface ComponentProps extends SizingProps {
  component: MatchedComponent<"staticMetric">
  componentSize: ComponentSize
}

/*
  COMPONENTS
*/

export const KPIComponent: React.FC<ComponentProps> = ({ component, componentSize, height }) => {
  const {
    config: { staticMetric },
  } = component

  const isEditLayoutActive = useIsEditLayoutActive()
  const dispatch = usePortalDispatch()

  const { title, periodName } = useComponentTitle(component)

  if (isEditLayoutActive) {
    return (
      <>
        <ComponentHeaderTitle componentSize={componentSize} title="KPI" periodName={undefined} />
        <KPIOptions
          component={component}
          dispatch={dispatch}
          datePickerLocation={DayPickerLocation.Top}
        />
      </>
    )
  }

  return (
    <>
      <ComponentHeaderTitle title={title} periodName={periodName} componentSize={componentSize} />
      <TileCard height={height}>
        <TileCardHeading css="overflow: hidden">
          {staticMetric.value || "Add Value & Unit"}
        </TileCardHeading>
        <div />
      </TileCard>
    </>
  )
}
