import * as React from "react"
import { type EntityCategory, type EntityParty } from "@digits-graphql/frontend/graphql-bearer"
import { usePopoverMutexContext } from "@digits-shared/components/UI/Elements/PopUp/PopOverMutexContext"
import { DigitsLinkButton } from "@digits-shared/DesignSystem/LinkButton"
import styled from "styled-components"
import { useReportEntityLink } from "src/frontend/components/OS/Applications/Reports/Report/hooks/useReportEntityLink"

/*
  STYLES
*/

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`

/*
  INTERFACES
*/

interface ButtonProps {
  linkObject?: EntityCategory | EntityParty
}

/*
  COMPONENTS
*/

export const PopOverHeaderButtons: React.FC<ButtonProps> = ({ linkObject }) => (
  <ButtonsContainer>
    <ViewLive linkObject={linkObject} />
  </ButtonsContainer>
)

const ViewLive: React.FC<ButtonProps> = ({ linkObject }) => {
  const path = useReportEntityLink(linkObject)
  const { clearActivePopOver } = usePopoverMutexContext()

  if (!path) return null

  return (
    <DigitsLinkButton $variant="secondary-dark" size="small" to={path} onClick={clearActivePopOver}>
      View Details
    </DigitsLinkButton>
  )
}
