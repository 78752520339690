import * as React from "react"
import { type DigitsLocation } from "@digits-shared/components/Router/DigitsLocation"
import { type DigitsRoute } from "@digits-shared/components/Router/DigitsRoute"
import urlHelper from "@digits-shared/helpers/urlHelper"
import {
  createPathGenerator,
  type PathGenerator,
  usePathGenerator,
} from "@digits-shared/hooks/usePathGenerator"
import useRouter from "@digits-shared/hooks/useRouter"
import routes from "src/frontend/routes"
import useIntervalOrigin from "src/shared/hooks/useIntervalOrigin"

export function useFrontendPathGenerator(): PathGenerator {
  const origin = useIntervalOrigin()
  return usePathGenerator(routes, origin)
}

// Generates a path with URLSearchParams instead of a Record which cant have duplicated keys
// e.g.
//    var search = new URLSearchParams(ids.map(s=>['id',s]))
//    var searchString = search.toString()
//    "id=1&id=2&id=3&id=4"
export function useFrontendSearchPathGenerator() {
  const generatePath = useFrontendPathGenerator()
  const { search } = useRouter()
  return React.useCallback(
    (to: DigitsRoute, searchParams: URLSearchParams) => {
      const url = urlHelper.fullDashboardURL(generatePath(to))
      const params = new URLSearchParams(search)

      // delete old values of new params
      for (const [key, _] of searchParams.entries()) {
        params.delete(key)
      }

      // append new params (set will not allow duplicated keys)
      for (const [key, value] of searchParams.entries()) {
        if (key === "__typename") continue
        const values: string[] = Array.isArray(value) ? value : [value]
        values.forEach((val) => {
          if (val) {
            params.append(key, val)
          }
        })
      }
      url.search = params.toString()
      return url.pathname + url.search
    },
    [generatePath, search]
  )
}

/** Creates a path generator function for use in places where we can't use a React hook */
// TODO: not a hook, find better location
export function createFrontendPathGenerator(location: DigitsLocation) {
  return createPathGenerator(routes, location)
}

export type FrontendPathGeneratorArguments = Parameters<
  ReturnType<typeof createFrontendPathGenerator>
>
