import * as React from "react"
import { useApolloClient } from "@apollo/client"
import {
  type LayoutComponent,
  type Report,
  type ReportDocumentOptions,
  ReportOptionComparison,
  type ReportOptionsFieldsFragment,
  useUpdateReportOptionsMutation,
} from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import useReportPackageContext from "src/frontend/components/OS/Applications/Reports/Packages/Viewer/ReportPackageContext"
import { useCollapsingSections } from "src/frontend/components/OS/Applications/Reports/Report/Options/CollapsingSections"
import { useLayoutDispatch } from "src/frontend/components/OS/Applications/Reports/Report/Viewer/Layout/layoutStore"
import { useComponentContext } from "src/frontend/components/OS/Applications/Reports/Report/Viewer/Layout/LayoutViewer/ComponentContext"
import { ReportOptionsContext } from "src/frontend/components/Shared/Layout/Components/Statements/ReportOptionsContext"
import type FrontendSession from "src/frontend/session"

/*
  CONTEXTS
*/

export const ReportOptionsContextProvider: React.FC<{
  report: Report
  children?: React.ReactNode
}> = ({ report, children }) => {
  const { options } = report
  const { mutation, loading } = useReportOptionsMutation(report)
  const { collapsedSections, setCollapsedSections, toggleCollapsedSection } = useCollapsingSections(
    options,
    mutation
  )

  const value = React.useMemo(
    () => ({
      options,
      mutation,
      loading,
      collapsedSections,
      setCollapsedSections,
      toggleCollapsedSection,
    }),
    [collapsedSections, loading, mutation, options, setCollapsedSections, toggleCollapsedSection]
  )

  return <ReportOptionsContext.Provider value={value}>{children}</ReportOptionsContext.Provider>
}

export const ComponentOptionsContextProvider: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  const { component } = useComponentContext()
  const options = component?.config.statement?.options
  const { mutation, loading } = useUpdateComponentOptionsMutation(component)
  const { collapsedSections, setCollapsedSections, toggleCollapsedSection } = useCollapsingSections(
    options,
    mutation
  )

  const value = React.useMemo(
    () => ({
      options,
      mutation,
      loading,
      collapsedSections,
      setCollapsedSections,
      toggleCollapsedSection,
    }),
    [collapsedSections, loading, mutation, options, setCollapsedSections, toggleCollapsedSection]
  )

  return <ReportOptionsContext.Provider value={value}>{children}</ReportOptionsContext.Provider>
}

function useReportOptionsMutation(report: Report) {
  const { currentLegalEntityId: legalEntityId } = useSession<FrontendSession>()
  const { reportPackage } = useReportPackageContext()
  const updateCache = useUpdateReportCache(report)

  const [updateOptions, { loading }] = useUpdateReportOptionsMutation({
    optimisticResponse: () => ({ updateReportOptions: {} }),
  })

  const mutation = React.useCallback(
    (newOptions: ReportDocumentOptions) => {
      if (!reportPackage) return
      const reportPackageId = reportPackage.id
      const packageVersionId = reportPackage.versionId
      const reportId = report.id
      const reportVersionId = report.versionId

      const options = {
        sparklineLookbackPeriods: undefined,
        deltaMonthOverMonth: undefined,
        deltaMonthOverMonthPeriods: undefined,
        deltaYearOverYear: undefined,
        comparedToTotal: undefined,
        comparedToIncome: undefined,
        amountPrecision: undefined,
        ...(report.options || {}),
        ...newOptions,
      }

      const variables = {
        legalEntityId,
        reportPackageId,
        packageVersionId,
        reportId,
        reportVersionId,
        ...options,
      }
      return updateOptions({ variables, update: () => updateCache(newOptions) })
    },
    [
      reportPackage,
      report.id,
      report.versionId,
      report.options,
      legalEntityId,
      updateOptions,
      updateCache,
    ]
  )

  return { mutation, loading }
}

export function useUpdateComponentOptionsMutation(component: LayoutComponent) {
  const builderDispatch = useLayoutDispatch()

  const mutation = React.useCallback(
    (newOptions: ReportDocumentOptions) => {
      const { config } = component
      if (!config.statement) return
      const options = buildNewOptions(config.statement.options, newOptions)

      const newConfig = {
        ...config,
        statement: {
          ...config.statement,
          options,
        },
      }

      const newComponent = { ...component, config: newConfig }

      builderDispatch({
        type: "updateComponent",
        component: newComponent,
      })

      return Promise.resolve()
    },
    [builderDispatch, component]
  )

  return { mutation, loading: false }
}

// CACHE UPDATING

function useUpdateReportCache(report: Report) {
  const { cache } = useApolloClient()
  return React.useCallback(
    (newOptions: ReportDocumentOptions) => {
      cache.modify({
        id: cache.identify(report),
        fields: {
          options(currentOptions: ReportDocumentOptions) {
            return buildNewOptions(currentOptions, newOptions)
          },
        },
      })
    },
    [cache, report]
  )
}

function buildNewOptions(
  currentOptions: ReportDocumentOptions | undefined | null,
  newOptions: ReportDocumentOptions
): ReportOptionsFieldsFragment {
  // replace "invalid" value for "null" values
  // to instantly update the UI when options are turned off
  const filteredOptions = Object.fromEntries(
    Object.entries(newOptions).map(([key, value]) => {
      const newValue =
        value === ReportOptionComparison.InvalidComparison ||
        (["sparklineLookbackPeriods", "deltaMonthOverMonthPeriods"].includes(key) && value === 0)
          ? null
          : value
      return [key, newValue]
    })
  )

  return {
    __typename: "ReportDocumentOptions",
    // NULL keys must match exactly ReportDocumentOptions interface or
    // creating a new entry in cache will fail (values will update until next hard refresh)
    ...(currentOptions || {
      sparkline: null,
      sparklineLookbackPeriods: null,
      deltaMonthOverMonth: null,
      deltaMonthOverMonthPeriods: null,
      deltaYearOverYear: null,
      comparedToTotal: null,
      comparedToIncome: null,
      collapsedSections: null,
      amountPrecision: null,
    }),
    ...filteredOptions,
  }
}
