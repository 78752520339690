import { useEffect } from "react"
import * as React from "react"
import { type StatementDetails } from "@digits-graphql/frontend/graphql-bearer"
import styled from "styled-components"
import { useBalanceDetails } from "src/frontend/components/OS/Applications/Reports/Report/Components/Shared/DetailsPopOver/useBalanceDetails"
import useRowSelectionContext from "src/frontend/components/OS/Applications/Reports/Report/Components/Statements/RowSelectionContext"
import { StatementTrendLineCell } from "src/frontend/components/OS/Applications/Reports/Report/Components/Statements/shared"
import {
  type RowDetails,
  useToTimeSeries,
} from "src/frontend/components/OS/Applications/Reports/Report/Components/Statements/toDetailsData"
import { useReportDocumentOptions } from "src/frontend/components/OS/Applications/Reports/Report/hooks/useReportDocumentOptions"
import { ParentSizedTimeseriesBarChart } from "src/frontend/components/Shared/Layout/Components/Charts/TimeseriesBarChart"
import { ParentSizedTimeseriesLineChart } from "src/frontend/components/Shared/Layout/Components/Charts/TimeseriesLineChart"
import { type TimeseriesValue } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"

/*
  STYLES
*/

const propsToStyle = (props: { $width: number }) => {
  const { $width } = props
  return {
    style: {
      width: `${$width}px`,
    },
  }
}

const ChartContainer = styled.div.attrs<{ $width: number }>(propsToStyle)`
  height: 11px;
  padding-right: 10px;
  box-sizing: content-box;
`

const BarChart = styled(ParentSizedTimeseriesBarChart)`
  svg {
    margin: 0;
  }
`

const LineChart = styled(ParentSizedTimeseriesLineChart)`
  svg {
    margin: 0;
  }
`

/*
  COMPONENTS
*/

export const StatementTrendLine: React.FC<{
  details?: RowDetails
}> = ({ details }) => {
  const periodDetails = details?.periodDetails

  const sparklineLookbackPeriods = useReportDocumentOptions()?.sparklineLookbackPeriods

  // Do not animate when the report first renders and trendline is on
  // only when the user changes the options for visual effect
  const [skipAnimations, unsetSkip] = React.useState(!!sparklineLookbackPeriods)
  useEffect(() => {
    if (!sparklineLookbackPeriods) {
      unsetSkip(false)
    }
  }, [sparklineLookbackPeriods])

  const amountOverTime = useToTimeSeries(periodDetails)

  if (!amountOverTime || !sparklineLookbackPeriods) return <StatementTrendLineCell />

  const { values } = amountOverTime

  const slices = values.slice(Math.max(values.length - sparklineLookbackPeriods, 0))
  const width = slices.length * 7

  return (
    <StatementTrendLineCell>
      <ChartContainer key={`interval_${sparklineLookbackPeriods}`} $width={width}>
        <Chart values={slices} details={periodDetails} skipAnimations={skipAnimations} />
      </ChartContainer>
    </StatementTrendLineCell>
  )
}

interface ChartProps {
  details?: StatementDetails | null
  values: TimeseriesValue[]
  skipAnimations: boolean
}

const Chart: React.FC<ChartProps> = ({ values, details, skipAnimations }) => {
  const isBalance = useBalanceDetails(details)
  const { setSelectedValue } = useRowSelectionContext()

  return isBalance ? (
    <LineChart
      timeseries={values}
      strokeWidth={2}
      hideGrid
      hideAxis
      noTooltip
      skipAnimations={skipAnimations}
      onMouseOver={(v) => setSelectedValue({ optionKey: "deltaMonthOverMonth", value: v })}
      onMouseOut={(v) => setSelectedValue(undefined)}
    />
  ) : (
    <BarChart
      timeseries={values}
      hideGrid
      hideAxis
      skipAnimations={skipAnimations}
      onMouseOver={(v) => setSelectedValue({ optionKey: "deltaMonthOverMonth", value: v })}
      onMouseOut={(v) => setSelectedValue(undefined)}
    />
  )
}
