import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { GLASS_LAYER } from "@digits-shared/DesignSystem/Containers/Glass"
import { isDigitsMacApp } from "@digits-shared/helpers/devicesHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"

export const ApplicationHeader = styled.div`
  ${GLASS_LAYER};
  position: absolute;
  top: 0;
  z-index: 2;

  width: var(--application-pane-full-width);
  height: var(--header-height);
  min-height: var(--header-height);

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  padding: 16px;
  color: ${colors.secondary};
  border-radius: 0 0 16px 16px;
  border-top: none;

  ${isDigitsMacApp &&
  css`
    padding-top: calc(16px + 22px);
    height: calc(var(--header-height) + 22px);
  `}
`

export const ApplicationIconStyles = css`
  height: 24px;
  width: 24px;
  display: block;
  ${svgPathStyles(colors.secondary, 1.5)};
`

export const ApplicationTitle = styled.div`
  ${fonts.scale.h2};
  font-weight: ${fonts.weight.heavy};
  display: flex;
  align-items: center;
  gap: 12px;
`
