import * as React from "react"
import { useRouteMatch } from "react-router-dom"
import { useApolloClient } from "@apollo/client"
import {
  type ReportPackage,
  type ReportPackageMetadata,
  type ReportPackageMetadataFieldsFragment,
  ReportPackageMetadataFieldsFragmentDoc,
} from "@digits-graphql/frontend/graphql-bearer"
import stringHelper from "@digits-shared/helpers/stringHelper"
import useSession from "@digits-shared/hooks/useSession"
import { COVER_IMAGES, DEFAULT_COVER_URL } from "src/frontend/components/Shared/Covers/coverImages"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"
import useIntervalOrigin from "src/shared/hooks/useIntervalOrigin"

const IMAGE_ENTRIES = Array.from(COVER_IMAGES, ([key, value]) => ({
  coverUrl: key,
  thumbUrl: value.thumb,
  fullUrl: value.full,
  rand: true,
}))

type PackageWithUrl = Pick<ReportPackageMetadata, "coverUrl">
type PackageWithOptions = Pick<ReportPackage, "packageOptions">
type ReportPackageCover = PackageWithUrl | PackageWithOptions
type ReportCoverUrl =
  | {
      thumbUrl: string
      fullUrl: string
      coverUrl?: string
      rand?: boolean
    }
  | undefined

function isPackageWithOptions(pkg: ReportPackageCover): pkg is PackageWithOptions {
  return "packageOptions" in pkg
}

export function useReportCoverUrl(reportPackage: ReportPackageCover | undefined): ReportCoverUrl {
  const { currentLegalEntityId } = useSession<FrontendSession>()
  const { year, index } = useIntervalOrigin()
  const cachedReport = useCachedReport()

  return React.useMemo(() => {
    if (!reportPackage && !cachedReport) {
      const legalEntityHash = stringHelper.simpleHashCode(currentLegalEntityId)
      const deterministicIndex = (year + index + legalEntityHash) % IMAGE_ENTRIES.length
      return IMAGE_ENTRIES[deterministicIndex]
    }

    const coverUrl =
      reportPackage && isPackageWithOptions(reportPackage)
        ? reportPackage.packageOptions?.coverUrl
        : (reportPackage || cachedReport)?.coverUrl

    // If there's a valid coverUrl, use its thumb, otherwise fall back to default
    const image = COVER_IMAGES.get(coverUrl || DEFAULT_COVER_URL)
    const thumbUrl = image?.thumb
    const fullUrl = image?.full

    if (!thumbUrl) {
      return undefined
    }

    return {
      coverUrl: coverUrl || undefined,
      thumbUrl,
      fullUrl: fullUrl || thumbUrl,
    }
  }, [cachedReport, currentLegalEntityId, index, reportPackage, year])
}

/**
 * Retrieves the cached report package metadata from Apollo cache based on the current route.
 * This hook is used to get cover URL information when the full report package data is not yet loaded.
 *
 * It looks for any ReportPackageMetadata entry in the cache that matches the package ID from the URL,
 * and returns the coverUrl field if found.
 */
function useCachedReport(): PackageWithUrl | null {
  const { cache } = useApolloClient()
  const viewMatch = useRouteMatch<{ packageId: string; versionId: string }>(
    routes.reportPackageVersion.parameterizedPath
  )
  const editMatch = useRouteMatch<{ packageId: string; versionId: string }>(
    routes.reportPackageEditVersion.parameterizedPath
  )
  const match = viewMatch || editMatch
  const id = match?.params.packageId
  const versionId = match?.params.versionId
  if (!id || !versionId) return null

  return cache.readFragment<ReportPackageMetadataFieldsFragment>({
    id: cache.identify({
      __typename: "ReportPackageMetadata",
      id,
      versionId,
    }),
    fragmentName: "ReportPackageMetadataFields",
    fragment: ReportPackageMetadataFieldsFragmentDoc,
  })
}
