import * as React from "react"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { DeltaChevron, DeltaContainer } from "@digits-shared/components/UI/Elements/Delta"
import { Amount } from "@digits-shared/components/UI/Table/Content"
import styled from "styled-components"
import {
  CategoryIconStyled,
  TopCategoriesList,
} from "src/frontend/components/OS/Applications/Reports/Report/Viewer/Layout/Shared/TopCategoriesList"
import { ComponentHeaderTitle } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { useComponentConfigTitle } from "src/frontend/components/Shared/Layout/Components/useComponentTitle"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useRemoveConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/ComponentConfigContext"
import { type TopEntitiesProps } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Lists/shared"
import { useTopCategoriesLiveData } from "src/frontend/components/Shared/Portals/hooks/useTopCategoriesLiveData"
import { type EntityCategoryTransactionSummary } from "src/frontend/types"

const StyledTopCategoriesList = styled(TopCategoriesList)`
  ${CategoryIconStyled} {
    ${svgPathStyles("#0c404b", 1.5)}
  }

  ${Amount} {
    color: #0c404b;
  }

  ${DeltaContainer} {
    color: #0c404b99;
    ${DeltaChevron} {
      border-bottom-color: #0c404b99;
      border-top-color: #0c404b99;
    }
  }
`

/*
  COMPONENTS
*/

export const TopCategoriesConfig: React.FC<TopEntitiesProps> = ({ config }) => {
  const topEntitiesConfig = config.topEntities
  const removeConfig = useRemoveConfig()
  const { title } = useComponentConfigTitle(config)

  const { data, loading } = useTopCategoriesLiveData(topEntitiesConfig, config.type)

  const categorySummaries: EntityCategoryTransactionSummary[] | undefined = React.useMemo(() => {
    if (!data) return undefined

    return data.entities.category.map((c) => ({
      category: c.categoryObject,
      summary: c.summary,
    }))
  }, [data])

  // remove this component if the results are empty
  React.useEffect(() => {
    if (categorySummaries && !categorySummaries?.length) {
      removeConfig()
    }
  }, [categorySummaries, removeConfig])

  return (
    <>
      <ComponentHeaderTitle
        title={title}
        componentSize={ComponentSize.Small}
        periodName={undefined}
      />
      <StyledTopCategoriesList categorySummaries={categorySummaries} loading={loading} />
    </>
  )
}
