import * as React from "react"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { DeltaChevron, DeltaContainer } from "@digits-shared/components/UI/Elements/Delta"
import { Amount } from "@digits-shared/components/UI/Table/Content"
import { DigitsTooltip } from "@digits-shared/DesignSystem/Tooltip"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import {
  CategoryIconStyled,
  TopCategoriesList,
} from "src/frontend/components/OS/Applications/Reports/Report/Viewer/Layout/Shared/TopCategoriesList"
import { ComponentHeaderTitle } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { useComponentTitle } from "src/frontend/components/Shared/Layout/Components/useComponentTitle"
import { useConfigOriginOverride } from "src/frontend/components/Shared/Layout/hooks/useConfigOriginOverride"
import { type TopEntitiesProps } from "src/frontend/components/Shared/Portals/Components/Lists/Shared"
import { useTopCategoriesLiveData } from "src/frontend/components/Shared/Portals/hooks/useTopCategoriesLiveData"
import { useTextIsOverflowing } from "src/frontend/hooks/useTextIsOverflowing"
import { type EntityCategoryTransactionSummary } from "src/frontend/types"

const StyledTopCategoriesList = styled(TopCategoriesList)`
  ${CategoryIconStyled} {
    ${svgPathStyles(colors.secondary50, 1.5)}
  }

  ${Amount}, ${DeltaContainer} {
    color: ${colors.secondary50};

    ${DeltaChevron} {
      border-bottom-color: ${colors.secondary50};
      border-top-color: ${colors.secondary50};
    }
  }
`

/*
  COMPONENTS
*/

export const TopCategories: React.FC<TopEntitiesProps> = ({ component, componentSize }) => {
  const config = useConfigOriginOverride(component.config.topEntities, 1)
  const { textElementRef, isTextOverflowing } = useTextIsOverflowing<HTMLDivElement>()
  const { title, periodName } = useComponentTitle(component)

  const { data, loading } = useTopCategoriesLiveData(config, component.config.type)

  const categorySummaries: EntityCategoryTransactionSummary[] | undefined = React.useMemo(
    () =>
      data?.entities.category.map((c) => ({
        category: c.categoryObject,
        summary: c.summary,
      })),
    [data]
  )

  return (
    <>
      <DigitsTooltip content={<div> {title}</div>} disabled={!isTextOverflowing}>
        <ComponentHeaderTitle
          ref={textElementRef}
          title={title}
          componentSize={componentSize}
          periodName={periodName}
        />
      </DigitsTooltip>
      <StyledTopCategoriesList categorySummaries={categorySummaries} loading={loading} />
    </>
  )
}
