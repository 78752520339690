import * as React from "react"
import { GRAPHQL_TRACE_KEY } from "@digits-shared/components/Contexts/GraphQLTracerContext"
import useForceUpdate from "@digits-shared/hooks/useForceUpdate"
import useSession from "@digits-shared/hooks/useSession"
import Session from "@digits-shared/session/Session"
import type FrontendSession from "src/frontend/session"
import { internalUserPreferenceKeys } from "src/shared/config/userPreferenceKeys"

export function useInternalUserSettings() {
  const session = useSession<FrontendSession>()
  const [_, forceUpdate] = useForceUpdate()

  // settings changes
  React.useEffect(() => {
    session.on?.(Session.USER_PREFERENCES_CHANGE, forceUpdate)
    return () => {
      session.off?.(Session.USER_PREFERENCES_CHANGE, forceUpdate)
    }
  }, [forceUpdate, session])

  const toggleUserSetting = React.useCallback(
    (setting: string, enabled?: boolean) => {
      forceUpdate()
      if (enabled === undefined) {
        return session.deleteUserPreference(setting)
      }
      session.setUserPreference(setting, enabled)
    },
    [forceUpdate, session]
  )

  const graphqlTracing = session.getBooleanUserPreference(GRAPHQL_TRACE_KEY)

  /**
   * Check if the grid overlay should be displayed
   */
  const gridOverlayEnabled = session.getBooleanUserPreference(
    internalUserPreferenceKeys.gridDevTools
  )

  /**
   * Check if the animation config dev tools preference is enabled.
   */
  const animationConfigDevToolsEnabled = session.getBooleanUserPreference(
    internalUserPreferenceKeys.animationConfigDevTools
  )

  /**
   * Disable Graphql batching
   */
  const graphqlNoBatching = session.getBooleanUserPreference(
    internalUserPreferenceKeys.graphqlNoBatching
  )

  /**
   * Check if statement summaries should be shown
   */
  const showStatementSummaries = session.getBooleanUserPreference(
    internalUserPreferenceKeys.showStatementSummaries
  )

  /**
   * Check if transaction action items should be shown
   */
  const showTransactionActionItems = session.getBooleanUserPreference(
    internalUserPreferenceKeys.showTransactionActionItems
  )

  /**
   * Check if inbox should be shown
   */
  const showInbox = session.getBooleanUserPreference(internalUserPreferenceKeys.inbox)

  return React.useMemo(
    () => ({
      graphqlTracing,
      gridOverlayEnabled,
      animationConfigDevToolsEnabled,
      toggleUserSetting,
      graphqlNoBatching,
      showTransactionActionItems,
      showInbox,
      showStatementSummaries,
    }),
    [
      graphqlTracing,
      gridOverlayEnabled,
      animationConfigDevToolsEnabled,
      toggleUserSetting,
      graphqlNoBatching,
      showTransactionActionItems,
      showInbox,
      showStatementSummaries,
    ]
  )
}
