import { Link } from "react-router-dom"
import colorHelper from "@digits-shared/helpers/colorHelper"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { REPORT_PACKAGE_ITEM_BORDER_RADIUS } from "src/frontend/components/OS/Applications/Reports/Browser/browserStyles"

export const SHARED_ENTITY_ROW_VERTICAL_SPACING = 8

export const SharedEntityRow = styled.div`
  position: relative;
  min-width: 700px;

  background-color: ${colorHelper.hexToRgba(colors.white, 0.02)};
  border: 1px solid ${colors.translucentSecondary10};
  border-radius: ${REPORT_PACKAGE_ITEM_BORDER_RADIUS}px;

  display: flex;
  justify-content: space-around;
  align-items: center;

  &:nth-child(2) {
    margin-top: ${SHARED_ENTITY_ROW_VERTICAL_SPACING}px;
  }

  margin-bottom: ${SHARED_ENTITY_ROW_VERTICAL_SPACING}px;
`

export const SharedDimensionSummaryRow = styled(SharedEntityRow)`
  transition: border 150ms ease-out;

  cursor: pointer;

  &:hover {
    box-shadow: inset 0 0 30px ${colors.translucentSecondary10};
    border: 1px solid ${colors.translucentSecondary20};
  }
`

export const SharedDimensionSummaryRowLink = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 24px;
`

export const SharedDimensionSummaryRowLoading = styled.div`
  width: 100%;
  height: 83px;
  display: flex;
  align-items: center;
  padding: 24px;
`
