import * as React from "react"
import { Flow, Interval, type StatementDeltaValue } from "@digits-graphql/frontend/graphql-bearer"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgArrowNarrowUpRight } from "@digits-shared/components/SVGIcons/line/ArrowNarrowUpRight.svg"
import { PointingDirection } from "@digits-shared/components/UI/Elements/Chevron"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { notNull } from "@digits-shared/helpers/filters"
import stringHelper from "@digits-shared/helpers/stringHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { SharedBarChartStyles } from "src/frontend/components/OS/Shared/Charts/styles"

/*
  STYLES
*/

const DeltaPill = styled.div`
  background-color: ${({ color }) => color};
  display: flex;
  align-items: center;
  padding: 4px 10px 4px 8px;
  border-radius: 14px;
  font-size: 13px;
  font-weight: ${fonts.weight.heavy};
  color: ${colors.secondary};
`

const DeltaArrow = styled(SvgArrowNarrowUpRight)<{ direction?: PointingDirection }>`
  ${svgPathStyles(colors.secondary, 2)};

  width: 16px;
  margin-right: 4px;
  transform: rotate(${({ direction }) => (direction === PointingDirection.Up ? 0 : 90)}deg);
  transition: transform 80ms ease;
`

/*
 INTERFACES
*/

interface TileDeltaProps {
  delta: StatementDeltaValue | undefined
  interval?: Interval
}

/*
 COMPONENTS
*/

export const TileDelta: React.FC<TileDeltaProps> = ({ delta, interval }) => {
  const moneyFlow = delta?.moneyFlow
  const percentageOfAmount = delta?.percentageOfAmount

  const displayPercentage = React.useMemo(() => {
    if (!percentageOfAmount) return 0
    const abs = Math.abs(percentageOfAmount)
    if (abs > 1000) {
      return "Over 1000%"
    }
    if (abs > 0 && abs < 1) {
      return "Under 1%"
    }
    if (abs === 0) return "Stable"

    return `${Math.round(abs)}%`
  }, [percentageOfAmount])

  const percentageSuffix = React.useMemo(() => {
    switch (interval) {
      case Interval.Month:
        return "MoM"
      case Interval.Quarter:
        return "QoQ"
      case Interval.Year:
        return "YoY"
      default:
        return null
    }
  }, [interval])

  if (percentageOfAmount === undefined || !moneyFlow) return null

  const isPositive = moneyFlow.businessFlow === Flow.Inbound
  const direction = percentageOfAmount > 0 ? PointingDirection.Up : PointingDirection.Down

  const color =
    (percentageOfAmount > 0 && isPositive) || (percentageOfAmount < 0 && !isPositive)
      ? SharedBarChartStyles.barFillBottomColor
      : SharedBarChartStyles.barFillNegativeColor

  return (
    <DeltaPill color={color}>
      <DeltaArrow direction={direction} />
      {[displayPercentage, percentageSuffix].filter(notNull).join(" ")}
    </DeltaPill>
  )
}

interface TileDeltaTimeProps {
  intervalCount: number | undefined
  interval?: Interval
}

export const TileDeltaTime: React.FC<TileDeltaTimeProps> = ({ intervalCount, interval }) => {
  if (!interval || !intervalCount) return null

  const direction =
    intervalCount && intervalCount > 0 ? PointingDirection.Up : PointingDirection.Down

  const color =
    direction === PointingDirection.Up
      ? SharedBarChartStyles.barFillBottomColor
      : SharedBarChartStyles.barFillNegativeColor

  return (
    <DeltaPill color={color}>
      <DeltaArrow direction={direction} />
      {stringHelper.titleCase(
        dateTimeHelper.displayNameForIntervalCount(interval, Math.abs(intervalCount))
      )}
    </DeltaPill>
  )
}
