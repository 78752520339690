import * as React from "react"
import { type MoneyFlow } from "@digits-graphql/frontend/graphql-bearer"
import { useInvertValues } from "@digits-shared/components/Contexts/InvertValuesContext"
import deltaHelper from "@digits-shared/helpers/deltaHelper"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import numberHelper, { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import { type Theme, useThemeMode } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import { chevronStyles } from "./Chevron"

enum DeltaLabels {
  Resumed = "Resumed",
  New = "New",
  Stable = "Stable",
}

/*
  STYLES
*/

export const DeltaPercent = styled.span`
  font-size: 11px;
`

export const DeltaValue = styled.div<DeltaProps>`
  text-transform: uppercase;
`

export const DeltaChevron = styled.div<DeltaProps>`
  ${(props) =>
    props &&
    chevronStyles(
      deltaHelper.deltaDirectionForValue(props),
      "3px",
      props.colorInChevron === false ? colors.white : deltaHelper.deltaValueColor(props)
    )};

  width: 7px;
  margin-right: 3px;
  margin-left: 0;
`

export const DeltaContainer = styled.div<DeltaProps & { toggleDeltaEnabled?: boolean }>`
  color: ${(props) => deltaHelper.deltaValueColor(props)};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  ${(props) =>
    props.toggleDeltaEnabled &&
    css`
      cursor: pointer;
      transition: text-shadow 250ms ease;

      &:hover {
        text-shadow: 0 0 10px ${deltaHelper.deltaValueColor(props)};
      }
    `}
`

/*
  INTERFACES
*/

export type DirectionForValueProps = DeltaValueProps & Pick<DeltaRenderProps, "isNew">

export interface DeltaValueProps {
  amount: number
  invertValues: boolean
  delta: number | null | undefined
}

export interface DeltaRenderProps {
  size?: string
  isNew?: boolean
  hasChevron?: boolean
  colorInChevron?: boolean
  className?: string
  themeMode?: Theme
  children?: React.ReactNode
}

export type DeltaProps = DeltaValueProps & DeltaRenderProps

/*
  COMPONENTS
*/

export const Delta: React.FC<
  Omit<DeltaValueProps, "invertValues"> &
    DeltaRenderProps & { allowDeltaToggle?: boolean; deltaValue?: MoneyFlow }
> = ({
  isNew,
  delta,
  hasChevron,
  children,
  size,
  className,
  colorInChevron,
  amount,
  allowDeltaToggle,
  deltaValue,
}) => {
  const invertValues = useInvertValues()
  const theme = useThemeMode()

  const toggleDeltaEnabled = allowDeltaToggle && !!deltaValue && delta !== 0 && delta !== null
  const { value: isAmountDisplay, toggle: toggleDeltaAmount } = useStateBoolean(toggleDeltaEnabled)

  const onDeltaClick = React.useCallback(
    (event: React.MouseEvent) => {
      if (!toggleDeltaEnabled) return

      event.stopPropagation()
      event.preventDefault()
      toggleDeltaAmount()
    },
    [toggleDeltaEnabled, toggleDeltaAmount]
  )

  const deltaAmount = deltaValue
    ? moneyFlowHelper.currency(deltaValue, {
        style: CurrencyStyle.Aggregation,
        signDisplay: "never",
      })
    : undefined

  const formattedDelta =
    children || (typeof delta === "number" && numberHelper.formatPercentage(Math.abs(delta)))
  const isNewValue = isNew || delta === null

  let value: React.ReactNode
  if (isNewValue) {
    // This is the first time we've seen transaction for this period
    value = DeltaLabels.New
  } else {
    // If the delta from the previous period was zero, then its stable. Otherwise, show the delta value
    value = delta === 0 ? DeltaLabels.Stable : formattedDelta
  }

  const showChevron =
    hasChevron !== false && value !== DeltaLabels.Stable && value !== DeltaLabels.New
  const displayDeltaPlus = !showChevron && typeof delta === "number" && delta > 0
  const deltaAmountPrefix =
    !showChevron && typeof delta === "number" ? deltaAmountIndicator(delta, invertValues) : ""

  return (
    <DeltaContainer
      toggleDeltaEnabled={toggleDeltaEnabled}
      delta={delta}
      amount={amount}
      invertValues={invertValues}
      hasChevron={showChevron}
      isNew={isNewValue}
      size={size}
      className={className}
      onClick={onDeltaClick}
      themeMode={theme}
    >
      {showChevron && !displayDeltaPlus && (
        <DeltaChevron
          colorInChevron={colorInChevron}
          delta={delta}
          amount={amount}
          invertValues={invertValues}
          isNew={isNewValue}
          hasChevron={showChevron}
          size={size}
          themeMode={theme}
        />
      )}
      {displayDeltaPlus && <React.Fragment>&#x2b;</React.Fragment>}
      <DeltaValue
        delta={delta}
        amount={amount}
        invertValues={invertValues}
        hasChevron={showChevron}
        isNew={isNewValue}
        size={size}
      >
        {isAmountDisplay ? `${deltaAmountPrefix}${deltaAmount}` : value}
      </DeltaValue>
    </DeltaContainer>
  )
}

function deltaAmountIndicator(delta: number, invertValues: boolean) {
  if (invertValues) return delta < 0 ? "" : "+"
  return delta > 0 ? "+" : ""
}
