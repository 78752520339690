import * as React from "react"
import { Link } from "react-router-dom"
import {
  type EntityLegalEntity,
  type EntityReportPackage,
  type SharedReportDocumentMetadata,
} from "@digits-graphql/frontend/graphql-bearer"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { FileType } from "@digits-shared/helpers/fileHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"
import {
  Downloader,
  PackageDocument,
  PackageDocumentDownloadContainer,
  PackageDocumentDownloadIcon,
  PackageDocumentLink,
  PackageDocumentName,
} from "src/frontend/components/OS/Applications/Reports/Browser/browserStyles"
import { DigitsPackageIconSize } from "src/frontend/components/OS/Applications/Reports/Icons/DigitsPackageIconSize"
import { ReportPackageIcon } from "src/frontend/components/OS/Applications/Reports/Icons/Icons"
import { SharedEntityRow } from "src/frontend/components/OS/Applications/SharedWithMe/Entities/EntityRows/Shared"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"

/*
  STYLES
*/

const ReportIconLink = styled(Link)`
  display: flex;
`

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  flex: 1;
`

const PackageContentsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  place-items: self-end;

  width: 230px;
  margin: 15px 0;
  padding-right: 40px;

  ${PackageDocument}, ${PackageDocumentLink} {
    line-height: 11px;
    padding: 7px 0;
    border-width: 0;

    &:last-child {
      padding-bottom: 0;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -20px;
  }
`

const PackageLabels = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 4px;
`

const PackageTimestamp = styled.div<{ color: string }>`
  border: 0.5px solid ${({ color }) => color};
  filter: drop-shadow(0px 0px 20px rgba(113, 245, 187, 0.41));
  border-radius: 50vh;
  padding: 2px 5px;
  text-transform: uppercase;
  color: ${({ color }) => color};
  font-size: 8px;
  font-weight: ${fonts.weight.heavy};
  line-height: 10px;
`

const ContentsLabel = styled.div`
  color: ${colors.translucentSecondary50};
  font-size: 10px;
  font-weight: ${fonts.weight.heavy};
  text-transform: uppercase;
  line-height: 9px;
  margin-bottom: 10px;
`

const PackagePeriodLabel = styled(Link)`
  font-size: 17px;
  font-weight: ${fonts.weight.heavy};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const PackageNameLabel = styled.div`
  color: ${colors.translucentWhite40};
  font-size: 10px;
  line-height: 18px;
`

const IconLoadingBlock = styled(LoadingBlock)`
  margin-left: 90px;
  margin-right: 90px;
`

const ContentsLoadingBlock = styled(LoadingBlock)`
  margin-bottom: 5px;
`

const HeaderLoadingBlock = styled(LoadingBlock)`
  margin-bottom: 15px;
`

/*
  COMPONENTS
*/
export const SharedReportPackageRow: React.FC<{
  legalEntity: EntityLegalEntity
  reportPackage: EntityReportPackage
  sharedAt: number
}> = ({ legalEntity, reportPackage, sharedAt }) => {
  const pathGenerator = useFrontendPathGenerator()

  const periodLabel = React.useMemo(
    () =>
      dateTimeHelper.displayNameFromUnixTimestamp(
        reportPackage.periodEndedAt,
        reportPackage.interval
      ),
    [reportPackage.interval, reportPackage.periodEndedAt]
  )

  const packagePath = pathGenerator(routes.reportPackage, {
    packageId: reportPackage.id,
    leSlug: legalEntity.slug,
  })

  return (
    <SharedEntityRow>
      <ReportIconLink to={packagePath}>
        <ReportPackageIcon
          size={DigitsPackageIconSize.Medium}
          reportPackage={reportPackage}
          legalEntity={legalEntity}
        />
      </ReportIconLink>
      <ActionContainer>
        <PackagePeriodLabel to={packagePath}>{periodLabel}</PackagePeriodLabel>
        <PackageNameLabel>{reportPackage.title}</PackageNameLabel>
        <PackageLabels>
          <PackageTimestamp color={colors.translucentSecondary70}>
            Shared {dayjs.unix(sharedAt).utc().format("MMM D")}
          </PackageTimestamp>
          {reportPackage.updatedAt && (
            <PackageTimestamp color={colors.translucentPrimary70}>
              Updated {dayjs.unix(reportPackage.updatedAt).utc().format("MMM D")}
            </PackageTimestamp>
          )}
        </PackageLabels>
        <Link
          css="display: flex; margin-top: 8px;"
          to={pathGenerator(routes.reportPackage, {
            packageId: reportPackage.id,
            leSlug: legalEntity.slug,
          })}
        >
          <DigitsButton size="small">View Report</DigitsButton>
        </Link>
      </ActionContainer>
      <PackageContentsContainer>
        <ContentsLabel>Report Contents</ContentsLabel>
        {reportPackage.documents.map((document) => (
          <SharedReportPackageDocument
            key={document.id}
            packageId={reportPackage.id}
            document={document}
            legalEntitySlug={legalEntity.slug}
            legalEntityId={legalEntity.id}
          />
        ))}
      </PackageContentsContainer>
    </SharedEntityRow>
  )
}

export const LoadReportPackage: React.FC = () => (
  <SharedEntityRow>
    <IconLoadingBlock width="100px" height="125px" />
    <ActionContainer>
      <ContentsLoadingBlock width="170px" height="20px" />
      <ContentsLoadingBlock width="170px" height="15px" />
      <PackageLabels>
        <ContentsLoadingBlock width="150px" height="20px" />
      </PackageLabels>
      <LoadingBlock width="120px" height="40px" />
    </ActionContainer>
    <PackageContentsContainer>
      <HeaderLoadingBlock width="120px" height="20px" />
      {[1, 2, 3, 4, 5].map((num) => (
        <ContentsLoadingBlock key={num} width="280px" height="20px" />
      ))}
    </PackageContentsContainer>
  </SharedEntityRow>
)

const SharedReportPackageDocument: React.FC<{
  packageId: string
  document: SharedReportDocumentMetadata
  legalEntitySlug?: string
  legalEntityId?: string
}> = ({ packageId, document, legalEntitySlug, legalEntityId }) => {
  const pathGenerator = useFrontendPathGenerator()

  const name = <PackageDocumentName>{document.title || "Custom Report"}</PackageDocumentName>

  if (document.mimeType !== FileType.DIGITS && document.mimeType !== "") {
    return (
      <PackageDocument>
        <Downloader document={document} reportPackageId={packageId} legalEntityId={legalEntityId}>
          {({ isDownloading }) => (
            <PackageDocumentDownloadContainer isDisabled={isDownloading}>
              {name}
              <PackageDocumentDownloadIcon />
            </PackageDocumentDownloadContainer>
          )}
        </Downloader>
      </PackageDocument>
    )
  }

  return (
    <PackageDocumentLink
      to={pathGenerator(routes.reportPackage, {
        packageId,
        deeplink: document.documentObjectId,
        leSlug: legalEntitySlug,
      })}
    >
      {name}
    </PackageDocumentLink>
  )
}
