import * as React from "react"
import ReactDOM from "react-dom"
import { useGenerateExecutiveSummaryQuery } from "@digits-graphql/frontend/graphql-bearer"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgAi } from "@digits-shared/components/SVGIcons/custom/Ai.svg"
import { ModalCenterPositioning } from "@digits-shared/components/UI/Elements/Modals/Modal"
import PendingIcon from "@digits-shared/components/UI/Elements/PendingIcon"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import caretHelper from "@digits-shared/helpers/caretHelper"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import errorHelper from "@digits-shared/helpers/errorHelper"
import { useModalRoot } from "@digits-shared/hooks/useModalRoot"
import useSession from "@digits-shared/hooks/useSession"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import colors from "@digits-shared/themes/colors"
import fonts, { BodyText, H2Text } from "@digits-shared/themes/typography"
import { type NodeHtmlMarkdown } from "node-html-markdown"
import styled, { css } from "styled-components"
import { useReportIntervalOrigin } from "src/frontend/components/OS/Applications/Reports/Report/hooks/useReportIntervalOrigin"
import type FrontendSession from "src/frontend/session"
import { AIResponse } from "src/shared/components/EditableContent/AIResponse"
import {
  NodeValues,
  TagNames,
} from "src/shared/components/EditableContent/editableContentConstants"
import DOMSanitize from "src/shared/components/ObjectEntities/DOMSanitize"
import { useStopClickPropagation } from "src/shared/hooks/useStopClickPropagation"

/*
 STYLES
*/

const ModalMask = styled.div`
  position: fixed;
  background-image: radial-gradient(
    172% 378% at -9.5% -24%,
    rgba(0, 92, 140, 0.1) 0%,
    rgba(0, 10, 15, 0) 100%
  );
  backdrop-filter: blur(2px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  overflow: auto;
`

const ModalContent = styled.div`
  width: 8in;
  min-height: 150px;
  max-height: 60vh;
  background: ${colors.white};
  border-radius: 16px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

const ICON_STYLES = css`
  height: 22px;
  width: 22px;
  ${svgIconStyles(colors.secondary)};
`

const Header = styled(H2Text)`
  font-weight: ${fonts.weight.roman};
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 24px;
`

const Content = styled(BodyText)`
  padding: 0 24px 24px;
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
`

const Footer = styled.div`
  border-top: 1px solid ${colors.secondary10};
  padding: 16px 16px 8px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

/*
 INTERFACES
*/

interface AIGenerator {
  editableContentRef: React.RefObject<HTMLElement | null>
}

interface Modal {
  nhm: NodeHtmlMarkdown
  closeModal: () => void
  editableContentRef: React.RefObject<HTMLElement | null>
}

/*
 COMPONENTS
*/

export const ExecutiveSummaryAIGenerator: React.FC<React.PropsWithChildren<AIGenerator>> = ({
  editableContentRef,
  children,
}) => {
  const { isDigitsEmployee } = useSession<FrontendSession>()
  const { value: showModal, setTrue: openModal, setFalse: closeModal } = useStateBoolean()
  const stopPropagation = useStopClickPropagation({ preventDefault: true })
  const nhm = React.useRef<NodeHtmlMarkdown>(null)

  const lazyLoadOnClick = React.useCallback(async () => {
    const { NodeHtmlMarkdown } = await import("node-html-markdown")
    nhm.current = new NodeHtmlMarkdown()
    openModal()
  }, [openModal])

  if (!isDigitsEmployee) return null

  return (
    <>
      <div css="display: contents" onMouseDown={stopPropagation} onClick={lazyLoadOnClick}>
        {children}
      </div>
      {showModal && nhm.current && (
        <NewSummaryModal
          nhm={nhm.current}
          closeModal={closeModal}
          editableContentRef={editableContentRef}
        />
      )}
    </>
  )
}

const NewSummaryModal: React.FC<Modal> = ({ nhm, closeModal, editableContentRef }) => {
  const {
    currentLegalEntity: { id: legalEntityId, name },
  } = useSession<FrontendSession>()
  const origin = useReportIntervalOrigin()
  const responseRef = React.useRef<HTMLDivElement>(null)

  const promptText = React.useMemo(() => {
    const selectedText = nhm.translate(
      window.getSelection()?.toString() || editableContentRef.current?.innerHTML || ""
    )
    return selectedText.trim() || "empty"
  }, [editableContentRef, nhm])

  const textReplacement = true // React.useMemo(() => !window.getSelection()?.isCollapsed, [])

  const { data, loading, error } = useGenerateExecutiveSummaryQuery({
    variables: {
      legalEntityId,
      prompt: `ENTITY_NAME:${name}\n DATE:${dateTimeHelper.displayNameFromIntervalOrigin(origin)}\n CONTEXT:${promptText}`,
    },
    fetchPolicy: "no-cache",
  })

  const onReplaceText = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      const newSummary = responseRef.current?.innerHTML
      const editableContent = editableContentRef.current
      if (!editableContent || !newSummary) return

      const newContent = DOMSanitize.sanitize(newSummary)
      const whiteSpace = document.createElement(TagNames.Span)
      whiteSpace.innerHTML = NodeValues.WhiteSpace
      newContent.appendChild(whiteSpace)

      editableContent.innerText = ""
      editableContent.appendChild(newContent)

      // const selection = window.getSelection()
      // const range = selection?.getRangeAt(0)
      // if (range) {
      //   if (!selection?.isCollapsed) {
      //     range.deleteContents()
      //   }
      //   range.insertNode(newContent)
      // }

      caretHelper.placeAtEnd(whiteSpace)
      closeModal()
    },
    [closeModal, editableContentRef]
  )

  const disabled = !data?.generateExecutiveSummary
  const modalRoot = useModalRoot()
  const stopPropagation = useStopClickPropagation({ preventDefault: true })
  return ReactDOM.createPortal(
    <ModalMask>
      <ModalCenterPositioning>
        <ModalContent onClick={stopPropagation}>
          <Header>
            <SvgAi css={ICON_STYLES} />
            Generated Executive Summary
          </Header>
          <Content>
            {loading && (
              <>
                Generating
                <PendingIcon color="inherit" />
              </>
            )}
            {error && <>{errorHelper.errorMessage(error)}</>}
            <AIResponse
              ref={responseRef}
              content={data?.generateExecutiveSummary?.replaceAll("\n \n", "\n\n") || ""}
            />
          </Content>
          <Footer>
            <DigitsButton onMouseDown={closeModal} $variant="secondary-dark" disabled={loading}>
              Cancel
            </DigitsButton>

            <DigitsButton onMouseDown={onReplaceText} $variant="primary" disabled={disabled}>
              {textReplacement ? "Replace" : "Insert"}
            </DigitsButton>
          </Footer>
        </ModalContent>
      </ModalCenterPositioning>
    </ModalMask>,
    modalRoot
  )
}
