import {
  type ActionItemFilter,
  ActionItemTargetMode,
  ObjectKind,
} from "@digits-graphql/frontend/graphql-bearer"
import { useDebuggingContext } from "src/frontend/components/Shared/NavSidebar/ActionItems/ActionItemsDebugging"
import { type GroupView } from "src/frontend/components/Shared/NavSidebar/ActionItems/ActionItemsSidebarContext"
import {
  STATUS_OPEN,
  STATUS_RESOLVED,
} from "src/frontend/components/Shared/NavSidebar/ActionItems/constants"
import { useInternalUserSettings } from "src/frontend/hooks/useInternalUserSettings"

export function useActionItemFilter(groupView: GroupView): ActionItemFilter {
  const { showTransactionActionItems } = useInternalUserSettings()
  const { assignedToUserId, assignedToOrganizationId } = useDebuggingContext()
  const excludeObjectKinds = showTransactionActionItems ? undefined : [ObjectKind.Transaction]
  switch (groupView) {
    case "Open":
      return {
        status: STATUS_OPEN,
        excludeFanouts: true,
        excludeObjectKinds,
        assignedToUserId,
        assignedToOrganizationId,
      }
    case "Resolved":
      return {
        status: STATUS_RESOLVED,
        includeSuperseded: true,
        excludeFanouts: true,
        excludeObjectKinds,
        assignedToUserId,
        assignedToOrganizationId,
      }
    case "Assigned":
      return {
        status: STATUS_OPEN,
        includeMode: [ActionItemTargetMode.Assign],
        excludeObjectKinds,
        assignedToUserId,
        assignedToOrganizationId,
      }
    case "Watched":
      return {
        status: STATUS_OPEN,
        includeMode: [ActionItemTargetMode.Watch],
        excludeObjectKinds,
        assignedToUserId,
        assignedToOrganizationId,
      }
  }
}
