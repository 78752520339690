import * as React from "react"
import fonts from "@digits-shared/themes/typography"
import remarkGfm from "remark-gfm"
import styled from "styled-components"

const SENTENCE_TICK = 120
const WORD_TICK = 50
const CHARACTER_TICK = 20

const SENTENCE_THRESHOLD = 4000
const WORD_THRESHOLD = 1500

// Lazy-load ReactMarkdown
const ReactMarkdown = React.lazy(() => import("react-markdown"))

/* STYLES */
const AIResponseContainer = styled.div`
  font-size: 14px;
  line-height: 23px;
  font-weight: ${fonts.weight.normal};
  border-radius: 8px;

  a {
    text-decoration: underline;
  }

  h1 {
    font-size: 18px;
    font-weight: ${fonts.weight.heavy};
  }

  h2 {
    font-size: 16px;
    font-weight: ${fonts.weight.heavy};
  }

  h3 {
    font-size: 14px;
    font-weight: ${fonts.weight.heavy};
  }

  h1,
  h2,
  ul,
  ol {
    padding: revert;
    margin: revert;
  }
`

interface AIResponseProps {
  content: string
  autoScroll?: boolean
}

/* COMPONENT */
export const AIResponse: React.FCWithRef<HTMLDivElement, AIResponseProps> = ({
  ref,
  content,
  autoScroll = true,
}) => {
  const [displayContent, setDisplayContent] = React.useState("")
  const markdownRef = React.useRef<HTMLDivElement>(null)
  const streamingTimeout = React.useRef<number>(null)

  // Whenever the content changes, restart streaming.
  React.useEffect(() => {
    setDisplayContent("")
    let currentIndex = 0
    const startTime = Date.now()

    // Recursive function to stream the content.
    const streamNext = () => {
      if (currentIndex >= content.length) return // Done streaming

      const elapsedTime = Date.now() - startTime
      const isVerySlow = elapsedTime > SENTENCE_THRESHOLD
      const isMediumSlow = elapsedTime > WORD_THRESHOLD

      const minTime = isVerySlow ? SENTENCE_TICK : isMediumSlow ? WORD_TICK : CHARACTER_TICK

      const intervalTime = Math.floor(Math.random() * (minTime * 2 - minTime + 1)) + minTime

      // Determine the next break point safely so that we never split a markdown token.
      let nextIndex: number
      if (isVerySlow) {
        // Try to break at sentence boundaries or markdown tag boundaries.
        nextIndex = findNextBreakPoint(content, currentIndex, ".!?\n")
      } else if (isMediumSlow) {
        // Try to break at word boundaries.
        nextIndex = findNextBreakPoint(content, currentIndex, " ")
      } else {
        // Character by character.
        nextIndex = findNextBreakPoint(content, currentIndex)
      }

      // Prevent stalling by ensuring progress is made.
      if (nextIndex <= currentIndex) {
        nextIndex = currentIndex + 1
      }
      currentIndex = nextIndex
      setDisplayContent(content.slice(0, currentIndex))

      streamingTimeout.current = window.setTimeout(streamNext, intervalTime)
    }

    streamNext()

    return () => {
      if (streamingTimeout.current) {
        clearTimeout(streamingTimeout.current)
      }
    }
  }, [content])

  // Add/update the streaming cursor on every content update.
  React.useLayoutEffect(() => {
    document.querySelector(".streaming-cursor")?.remove()
    // If streaming is complete, do nothing.
    if (displayContent === content) return
    let lastElement = markdownRef.current?.lastElementChild
    while (lastElement?.lastElementChild) {
      lastElement = lastElement.lastElementChild
    }

    if (
      lastElement?.parentElement?.lastChild?.nodeType === Node.TEXT_NODE &&
      lastElement?.parentElement?.lastChild.textContent !== "\n"
    ) {
      lastElement = lastElement.parentElement
    }
    if (!lastElement) return

    // Create a new cursor element.
    const cursor = document.createElement("span")
    cursor.className = "streaming-cursor"
    cursor.innerText = "▄"
    cursor.style.opacity = `${Math.random()}`
    lastElement?.appendChild(cursor)

    if (autoScroll) {
      cursor.scrollIntoView({ behavior: "instant", block: "end" })
    }
  }, [displayContent, content, autoScroll])

  if (!content) return null

  return (
    <AIResponseContainer ref={ref}>
      <React.Suspense fallback={<div />}>
        <div ref={markdownRef} key={displayContent}>
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{displayContent}</ReactMarkdown>
        </div>
      </React.Suspense>
    </AIResponseContainer>
  )
}

/* MARKDOWN TOKEN HANDLING */

// Define a comprehensive list of markdown tokens.
interface MarkdownToken {
  start: string
  end: string
}

const markdownTokens: MarkdownToken[] = [
  { start: "```", end: "```" }, // code block
  { start: "`", end: "`" }, // inline code
  { start: "**", end: "**" }, // bold
  { start: "__", end: "__" }, // bold (alternative)
  { start: "*", end: "*" }, // italic
  { start: "_", end: "_" }, // italic (alternative)
  { start: "~~", end: "~~" }, // strikethrough
  { start: "![", end: ")" }, // image syntax: ![alt](url)
  { start: "[", end: ")" }, // link syntax: [text](url)
]

/**
 * Checks whether the given text ends with an ambiguous line.
 * In our case, an ambiguous line is one that consists solely of dashes (with possible whitespace),
 * which Markdown may interpret as a setext heading underline.
 */
export function isAmbiguousEnding(text: string): boolean {
  const lines = text.split("\n")
  // Find the last non-empty line.
  for (let i = lines.length - 1; i >= 0; i--) {
    const line = lines[i]?.trim() ?? ""
    if (line.length > 0) {
      return /^-+$/.test(line)
    }
  }
  return false
}

/**
 * Finds the next safe break point in the markdown content.
 *
 * @param content The full markdown string.
 * @param currentIndex The current index.
 * @param breakOn Optional characters (e.g., " " for word boundaries) to break on.
 * @returns A safe index to slice the content, ensuring we don't split tokens
 *          or leave an ambiguous ending that could trigger unwanted formatting.
 */
export function findNextBreakPoint(
  content: string,
  currentIndex: number,
  breakOn?: string
): number {
  let candidate: number | null = null

  // 1. Check if we're about to start a markdown token.
  for (const token of markdownTokens) {
    if (content.startsWith(token.start, currentIndex)) {
      const tokenEnd = content.indexOf(token.end, currentIndex + token.start.length)
      if (tokenEnd !== -1) {
        candidate = tokenEnd + token.end.length
        break
      }
    }
  }

  // 2. Check if we're inside a markdown token and jump to its end.
  if (candidate === null) {
    for (const token of markdownTokens) {
      const tokenStart = content.lastIndexOf(token.start, currentIndex)
      if (tokenStart !== -1) {
        const tokenEnd = content.indexOf(token.end, tokenStart + token.start.length)
        if (
          tokenEnd !== -1 &&
          currentIndex > tokenStart &&
          currentIndex < tokenEnd + token.end.length
        ) {
          candidate = tokenEnd + token.end.length
          break
        }
      }
    }
  }

  // 3. If breakOn characters are provided, break at the first occurrence.
  if (candidate === null && breakOn) {
    // Escape regex special characters.
    const escaped = breakOn.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")
    const pattern = new RegExp("[" + escaped + "]")
    const match = pattern.exec(content.slice(currentIndex))
    if (match) {
      candidate = currentIndex + match.index + 1
    } else {
      candidate = content.length
    }
  }

  // 4. Default: advance one character.
  if (candidate === null) {
    candidate = currentIndex + 1
  }

  // 5. Safety step: If the current slice ends with an ambiguous dash-only line,
  // keep extending the candidate until the ambiguity is resolved.
  while (candidate < content.length && isAmbiguousEnding(content.slice(0, candidate))) {
    candidate++
  }

  return candidate
}
