import * as React from "react"
import { type ReportKind } from "@digits-graphql/frontend/graphql-bearer"
import { type ColumnKey } from "src/frontend/components/OS/Applications/Reports/Report/Components/Statements/columnTypes"
import { type StatementSize } from "src/frontend/components/OS/Applications/Reports/Report/Components/Statements/shared"
import { type TimeseriesValue } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"

/*
  CONTEXTS
*/

export type PinnedCell = {
  rowId: string
  target: HTMLElement
}

export interface SelectedValue {
  optionKey: ColumnKey
  value: TimeseriesValue
}

interface StatementContextProps {
  kind?: ReportKind
  size?: StatementSize
}

export const StatementContext = React.createContext<StatementContextProps>({})

export function useStatementSize() {
  return React.useContext(StatementContext)?.size
}

export function useStatementKind() {
  return React.useContext(StatementContext)?.kind
}
