import * as React from "react"
import { chevronStyles, PointingDirection } from "@digits-shared/components/UI/Elements/Chevron"
import { isDigitsMacApp } from "@digits-shared/helpers/devicesHelper"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import { useNavSidebarContext } from "src/frontend/components/Shared/Contexts/useNavSidebarContext"
import { SidebarRow } from "src/frontend/components/Shared/NavSidebar/SidebarExpando"
import { SidebarMenu } from "src/frontend/components/Shared/NavSidebar/SidebarMenu"
import type FrontendSession from "src/frontend/session"
import { EntityIcon } from "src/shared/components/ObjectEntities/EntityIcon"

/*
  STYLES
*/

const Header = styled.div`
  width: 100%;
  margin-bottom: 32px;

  ${isDigitsMacApp &&
  css`
    padding-top: 40px;
  `};
`

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  div:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const BusinessIcon = styled(EntityIcon)`
  margin: 0 0 0 -8px;
  padding: 0;
`

const Chevron = styled.div<{
  expanded: boolean
}>`
  ${({ expanded }) =>
    chevronStyles(
      expanded ? PointingDirection.Up : PointingDirection.Down,
      "5px",
      colors.secondary
    )};
`

const HeaderRow = styled(SidebarRow)`
  border: 1px solid ${colors.transparent};
  margin: 12px auto;
  padding-top: 4px;
  padding-bottom: 4px;
`

/*
  COMPONENT
*/

export const SidebarHeader: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  const {
    currentLegalEntity,
    currentAffiliation,
    currentAffiliatedOrganization,
    currentOrganization,
  } = useSession<FrontendSession>()
  const legalEntity = currentAffiliation?.entity || currentLegalEntity
  const iconUrl = (currentAffiliatedOrganization || currentOrganization)?.iconUrl
  const { onMouseLeaveHeaderMenu, isHeaderMenuOpen, toggleHeaderMenu, hideHeaderMenu } =
    useNavSidebarContext()
  return (
    <Header onMouseLeave={disabled ? undefined : onMouseLeaveHeaderMenu}>
      <HeaderRow
        rowType="button"
        disabled={disabled}
        onClick={toggleHeaderMenu}
        element={
          <BusinessIcon imageUrl={iconUrl} size={40}>
            {legalEntity.name}
          </BusinessIcon>
        }
      >
        <HeaderTitle>
          <div css="flex: 1">{legalEntity.name}</div>
          {!disabled && <Chevron expanded={isHeaderMenuOpen} />}
        </HeaderTitle>
      </HeaderRow>
      {!disabled && <SidebarMenu isOpen={isHeaderMenuOpen} hideMenu={hideHeaderMenu} />}
    </Header>
  )
}
