import * as React from "react"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import { Amount } from "@digits-shared/components/UI/Table/Content"
import { DigitsTooltip } from "@digits-shared/DesignSystem/Tooltip"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import { themedSVGPathStyles } from "@digits-shared/themes/svgIconStyles"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { useReportComponentIntervalOrigin } from "src/frontend/components/OS/Applications/Reports/Report/Viewer/Layout/hooks/useReportComponentIntervalOrigin"
import {
  type SummaryListData,
  TopList,
} from "src/frontend/components/OS/Applications/Reports/Report/Viewer/Layout/Shared/WidgetSummaryList"
import { DimensionTotalAmountWithDelta } from "src/frontend/components/OS/Shared/Dimensions/DimensionRow"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import { useTextIsOverflowing } from "src/frontend/hooks/useTextIsOverflowing"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"
import { type EntityCategoryTransactionSummary } from "src/frontend/types"
import { SVGIconComponent } from "src/shared/components/Icons/SVGIcon"
import { useStopClickPropagation } from "src/shared/hooks/useStopClickPropagation"

/*
  INTERFACES
*/

interface TopCategoriesListProps {
  className?: string
  categorySummaries: EntityCategoryTransactionSummary[] | undefined
  loading: boolean
  padding?: number
}

/*
  STYLES
*/

export const CategoryIconStyled = styled(SVGIconComponent)`
  height: 23px;
  width: 23px;
  padding: 2px;
  background: transparent;
  ${themedSVGPathStyles({ light: colors.translucentSecondary60, dark: colors.white }, 1.5)};
`

const Name = styled.div`
  font-size: 12px;
  font-weight: ${fonts.weight.normal};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const AmountDelta = styled(DimensionTotalAmountWithDelta)`
  text-align: right;
  font-size: 10px;

  ${Amount} {
    font-size: 12px;
    font-weight: ${fonts.weight.normal};
  }
`

/*
  COMPONENTS
*/

export const TopCategoriesList = React.memo<TopCategoriesListProps>(
  ({ className, categorySummaries, loading, padding = 10 }) => {
    const { history } = useRouter()
    const stopPropagation = useStopClickPropagation()
    const generatePath = useFrontendPathGenerator()
    const intervalOrigin = useReportComponentIntervalOrigin(12)
    const { isSharingContextActive } = useSession<FrontendSession>()

    const goToCategoryDetails = React.useCallback(
      (data: EntityCategoryTransactionSummary, e: React.MouseEvent) => {
        stopPropagation(e)
        history.push(
          generatePath(routes.categoryDetails, { categoryId: data.category.id, ...intervalOrigin })
        )
      },
      [generatePath, history, intervalOrigin, stopPropagation]
    )
    const onClick = isSharingContextActive ? undefined : goToCategoryDetails

    return (
      <TopList
        className={className}
        padding={padding}
        data={categorySummaries}
        loading={loading}
        activeIndex={undefined}
        IconComponent={CategoryIcon}
        LabelComponent={CategoryLabel}
        SummaryComponent={CategorySummary}
        onClick={onClick}
      />
    )
  }
)

const CategoryIcon: React.FC<SummaryListData<EntityCategoryTransactionSummary>> = ({ data }) => {
  if (!data) return null

  const { displayKey, name } = data.category
  return <CategoryIconStyled subjectDisplayKey={displayKey || name} />
}

const CategoryLabel: React.FC<SummaryListData<EntityCategoryTransactionSummary>> = ({ data }) => {
  const { textElementRef, isTextOverflowing } = useTextIsOverflowing<HTMLDivElement>()
  if (!data) return <LoadingBlock width="100px" height="30px" />

  const { name } = data.category
  return (
    <DigitsTooltip
      css=" overflow: hidden"
      content={<div>{name}</div>}
      disabled={!isTextOverflowing}
    >
      <Name ref={textElementRef}>{name}</Name>
    </DigitsTooltip>
  )
}

const CategorySummary: React.FC<SummaryListData<EntityCategoryTransactionSummary>> = ({
  loading,
  data,
}) => {
  if (loading || !data) return null

  const { summary } = data
  return <AmountDelta summary={summary} allowDeltaToggle />
}
