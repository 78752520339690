import * as React from "react"
import {
  type EntityCategory,
  type EntityTransaction,
  Interval,
  type Transaction,
  type TransactionParty,
  TransactionRecordType,
} from "@digits-graphql/frontend/graphql-bearer"
import { LoadingCircle } from "@digits-shared/components/Loaders"
import { IconSize } from "@digits-shared/components/UI/Icons/Icon"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"
import { PartyIconHover } from "src/shared/components/PartyHover"
import { TransactionCategoryIcon } from "src/shared/components/Transactions/TransactionRow/TransactionCategoryIcon"
import {
  getTransactionRowFacilitator,
  getTransactionRowInstitution,
  getTransactionRowParty,
  isTransactionGroup,
  type TransactionRowProps,
} from "src/shared/components/Transactions/TransactionRow/types"
import { useTransactionsTableContext } from "src/shared/components/Transactions/TransactionsTableContext"
import transactionHelper from "src/shared/helpers/transactionHelper"

interface Props {
  party: TransactionParty | undefined | null
  category: EntityCategory | undefined | null
  iconSize?: IconSize
}

/*
  COMPONENTS
*/

export const TransactionPartyIcon: React.FC<
  TransactionRowProps & { iconSize?: IconSize; neverHover?: boolean; withClickTarget?: boolean }
> = ({ data, loading, iconSize = IconSize.SmallMedium, neverHover, withClickTarget }) => {
  if (loading || !data) {
    const size = `${iconSize.pixels}px`
    return <LoadingCircle width={size} height={size} />
  }

  const transaction = isTransactionGroup(data) ? data.transactions[0] : data
  const { displayCategory } = transaction || {}

  if (transactionHelper.isTransfer(transaction)) {
    return null
  }

  const party =
    getTransactionRowParty(transaction) ||
    getTransactionRowFacilitator(transaction) ||
    getTransactionRowInstitution(transaction)

  return (
    transaction && (
      <TransactionIcon
        transaction={transaction}
        party={party}
        category={displayCategory}
        iconSize={iconSize}
        neverHover={neverHover}
        withClickTarget={withClickTarget}
      />
    )
  )
}

export default TransactionPartyIcon

export const TransactionIcon: React.FC<
  Props & {
    transaction: EntityTransaction | Transaction
    neverHover?: boolean
    withClickTarget?: boolean
  }
> = ({ transaction, iconSize, party, category, neverHover, withClickTarget }) => {
  const { timestamp } = transaction
  const recordType = (transaction as Transaction).recordType || TransactionRecordType.Transaction

  const { legalEntityId } = useTransactionsTableContext()
  // render party icon if available
  if (party?.id) {
    const period = {
      interval: Interval.Month,
      startedAt: dayjs.unix(timestamp).utc().unix(),
    }

    return (
      <PartyIconHover
        legalEntityId={legalEntityId}
        intervalOrigin={dateTimeHelper.intervalOriginFromPeriod(period)}
        party={party}
        partyIconSize={iconSize}
        neverHover={neverHover}
        withClickTarget={withClickTarget}
      />
    )
  }

  // then fall back to category icon
  return <TransactionCategoryIcon recordType={recordType} category={category} iconSize={iconSize} />
}
