import * as React from "react"
import {
  PageScrollContext,
  type PageScrollContextProps,
} from "@digits-shared/components/UI/Elements/PageScrollContext"

/**
 * Returns the Page's Scroll Context
 */
export default function useScrollContext<T extends HTMLElement = HTMLDivElement>() {
  const scrollContext = React.useContext(PageScrollContext) as PageScrollContextProps<T>

  // Memoize so that a new object is only returned if something changes
  return React.useMemo(() => scrollContext, [scrollContext])
}
