import * as React from "react"
import { type UseMeasureRect } from "react-use/lib/useMeasure"
import { type EntityTransaction, type Transaction } from "@digits-graphql/frontend/graphql-bearer"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgTransfers } from "@digits-shared/components/SVGIcons/custom/Transfers.svg"
import { ColorIconStyled, getIconColor } from "@digits-shared/components/UI/Elements/ColorIcon"
import { IconContainer, IconSize, IconSVGContainer } from "@digits-shared/components/UI/Icons/Icon"
import { Amount } from "@digits-shared/components/UI/Table/Content"
import { DigitsTooltip } from "@digits-shared/DesignSystem/Tooltip"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import { useMeasure } from "@digits-shared/hooks/useMeasure"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import { themedStyles } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"
import {
  ListItem,
  type SummaryListData,
  TopList,
} from "src/frontend/components/OS/Applications/Reports/Report/Viewer/Layout/Shared/WidgetSummaryList"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import { useTextIsOverflowing } from "src/frontend/hooks/useTextIsOverflowing"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"
import { PartyIcon } from "src/shared/components/PartyHover/PartyIcon"
import { CategoryIconContainer } from "src/shared/components/Transactions/TransactionRow/TransactionCategoryIcon"
import transactionHelper from "src/shared/helpers/transactionHelper"
import { useStopClickPropagation } from "src/shared/hooks/useStopClickPropagation"

/*
  STYLES
*/

const IconStyled = styled.div`
  display: flex;
  align-items: center;

  ${IconContainer} {
    margin: 0;
  }

  ${CategoryIconContainer} {
    height: 24px;
    width: 24px;
  }

  ${ColorIconStyled} {
    font-size: 8px;
  }

  ${IconSVGContainer} {
    padding: 2px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`

const StyledAmount = styled(Amount)`
  text-align: right;
  padding: 8px 0 7px;

  font-size: 11px;
  font-weight: ${fonts.weight.medium};
`

const List = styled(TopList)<{ showDate: boolean }>`
  ${ListItem} {
    ${({ padding, showDate }) =>
      `grid-template-columns: ${(padding ?? 10) - 10}px ${showDate ? "73px" : "25px"} 1fr auto ${
        (padding ?? 10) - 10
      }px;`}
  }
`

export const RowContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const titleStyles = themedStyles({
  light: css`
    color: ${colors.secondary};
  `,
  dark: css`
    color: ${colors.white};
  `,
})

export const Title = styled.div`
  ${titleStyles};
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: ${fonts.weight.normal};
`

export const Description = styled.div`
  color: ${colors.regentGray};
  font-size: 11px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: ${fonts.weight.book};
`

export const Date = styled.div`
  color: ${colors.regentGray};
  font-weight: ${fonts.weight.black};
  text-transform: uppercase;
  font-size: 10px;
  margin-right: 10px;
`

export const Month = styled.div`
  margin-top: 1px;
`

export const Year = styled.div`
  font-weight: ${fonts.weight.book};
`

const TransferIconContainer = styled(IconContainer)`
  background-color: ${getIconColor().background};
  width: 16px;
  height: 16px;
  margin-right: 10px;
`

const TransferIcon = styled(SvgTransfers)`
  padding: 1px;
  width: 30px;
  height: 24px;
  ${svgIconStyles(colors.white)};
`

const TransferRow = styled.div`
  font-size: 11px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

const TransferText = styled.span`
  margin: 0 5px;
  opacity: 0.5;
  flex-shrink: 0;
  font-size: 11px;
`

/*
  INTERFACES
*/

// TODO: Unify on EntityTransaction if we get a dedicated go-services endpoint
//       for the live data fetch
type DisplayableTransaction = Transaction | EntityTransaction

interface TopTransactionsListProps {
  transactions: DisplayableTransaction[] | undefined
  loading: boolean
  padding?: number
  className?: string
}

/*
  CONTEXT
*/

/**
 * Lets us make a parent measurement available to a child component
 * that we can't pass props to.
 */
const MeasureContext = React.createContext<UseMeasureRect>({
  x: 0,
  y: 0,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  width: 0,
  height: 0,
})

function useShowDate() {
  const { width } = React.useContext(MeasureContext)

  return shouldShowDate(width)
}

function shouldShowDate(width: number) {
  return width > 280
}

/*
  COMPONENT
*/

export const TopTransactionsList = React.memo<TopTransactionsListProps>(
  ({ transactions, loading, padding, className }) => {
    const [ref, rect] = useMeasure<HTMLDivElement>()

    const { history } = useRouter()
    const generatePath = useFrontendPathGenerator()
    const stopPropagation = useStopClickPropagation()
    const { isSharingContextActive } = useSession<FrontendSession>()

    const goToTransactionDetails = React.useCallback(
      (data: Transaction, e: React.MouseEvent) => {
        stopPropagation(e)
        history.push(
          generatePath(routes.defaultTransactionDetails, {
            transactionId: data.factId,
          })
        )
      },
      [generatePath, history, stopPropagation]
    )
    const onClick = isSharingContextActive ? undefined : goToTransactionDetails

    return (
      <MeasureContext.Provider value={rect}>
        <List
          className={className}
          showDate={shouldShowDate(rect.width)}
          measureRef={ref}
          padding={padding}
          data={transactions}
          loading={loading}
          activeIndex={undefined}
          IconComponent={Icon}
          LabelComponent={TransactionLabel}
          SummaryComponent={TransactionAmount}
          onClick={onClick}
        />
      </MeasureContext.Provider>
    )
  }
)

const Icon: React.FC<SummaryListData<DisplayableTransaction>> = ({ data }) => {
  const showDate = useShowDate()

  if (!data) return null

  const { party, timestamp } = data
  const date = dayjs.unix(timestamp).utc()

  const icon = transactionHelper.isTransfer(data) ? (
    <TransferIconContainer>
      <TransferIcon />
    </TransferIconContainer>
  ) : (
    <PartyIcon size={IconSize.Small} party={party} css="margin-right: 10px;" disableHoverBorder />
  )

  return (
    <IconStyled>
      {showDate && (
        <Date>
          <Month>{date.format("MMM DD")}</Month>
          <Year>{date.format("YYYY")}</Year>
        </Date>
      )}
      {icon}
    </IconStyled>
  )
}

const TransactionLabel: React.FC<SummaryListData<DisplayableTransaction>> = ({ data }) => {
  const { textElementRef, isTextOverflowing } = useTextIsOverflowing<HTMLDivElement>()
  if (!data) return <LoadingBlock width="100px" height="30px" />

  if (transactionHelper.isTransfer(data)) {
    return (
      <TransferRow>
        <TransferText css="margin-left:0px;">Transfer from</TransferText>
        {data.displayCategory?.name ?? "Uncategorized"}
        <TransferText css="margin-right: 0px;">to</TransferText>{" "}
        {data.splitCategory?.name ?? "Uncategorized"}
      </TransferRow>
    )
  }

  const { party } = data
  const title = party?.name || data.description || data.name || ""

  return (
    <RowContent>
      <DigitsTooltip
        css=" overflow: hidden"
        content={<div>{title}</div>}
        disabled={!isTextOverflowing}
      >
        <Title ref={textElementRef}>{title}</Title>
      </DigitsTooltip>
      <Description>{data.displayCategory.name || "Uncategorized"}</Description>
    </RowContent>
  )
}

const TransactionAmount: React.FC<SummaryListData<DisplayableTransaction>> = ({ data }) => {
  if (!data) return null

  const { moneyFlow } = data

  const currency = moneyFlowHelper.currency(moneyFlow, {
    style: CurrencyStyle.Detail,
  })

  return (
    <StyledAmount flow={moneyFlow.businessFlow} normal={moneyFlow.isNormal}>
      {currency}
    </StyledAmount>
  )
}
