import * as React from "react"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgHome02 } from "@digits-shared/components/SVGIcons/line/Home02.svg"
import { SvgSearchSm } from "@digits-shared/components/SVGIcons/line/SearchSm.svg"
import { SvgStars01 } from "@digits-shared/components/SVGIcons/line/Stars01.svg"
import { SvgUserPlus01 } from "@digits-shared/components/SVGIcons/line/UserPlus01.svg"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import {
  ApplicationHeader,
  ApplicationIconStyles,
  ApplicationTitle,
} from "@digits-shared/DesignSystem/Containers/Header"
import { DigitsLinkButton } from "@digits-shared/DesignSystem/LinkButton"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import {
  RequiresComment,
  RequiresCreate,
} from "src/frontend/components/Shared/Permissions/Requires"
import { useOperatorBillingPlan } from "src/frontend/components/Shared/PLG/useOperatorBillingPlan"
import { InviteClientsModalState } from "src/frontend/components/Shared/Portals/State/types"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
} from "src/frontend/session/permissionModule"

/*
  STYLES
*/

const HomeIcon = styled(SvgHome02)`
  ${ApplicationIconStyles};
`

const ButtonsContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 14px;
`

const SearchIcon = styled(SvgSearchSm)`
  width: 16px;
  height: 16px;
  ${svgPathStyles(colors.secondary, 1.5)};
`

const AssistantIcon = styled(SvgStars01)`
  width: 16px;
  height: 16px;
`

/*
  COMPONENTS
*/

export const HomeHeader: React.FC = () => (
  <ApplicationHeader>
    <ApplicationTitle data-testid="home-title">
      <HomeIcon />
      Home
    </ApplicationTitle>

    <ButtonsContainer>
      <SearchButton />
      <AssistantButton />
      <InviteClientButton />
    </ButtonsContainer>
  </ApplicationHeader>
)

const SearchButton: React.FC = () => {
  const generatePath = useFrontendPathGenerator()
  return (
    <DigitsLinkButton to={generatePath(routes.search)} $variant="secondary-dark" $circle>
      <SearchIcon />
    </DigitsLinkButton>
  )
}

const AssistantButton: React.FC = () => {
  const generatePath = useFrontendPathGenerator()
  const assistantPath = {
    pathname: generatePath(routes.legalEntityHomeAssistant),
  }

  return (
    <RequiresComment
      source={FrontendPermissionSource.LegalEntity}
      module={FrontendPermissionModule.Portals}
    >
      <DigitsLinkButton to={assistantPath} $variant="secondary-dark" $circle>
        <AssistantIcon />
      </DigitsLinkButton>
    </RequiresComment>
  )
}

const AddIcon = styled(SvgUserPlus01)`
  width: 16px;
  height: 16px;
  ${svgPathStyles(colors.secondary, 2)};
`

const InviteClientButton: React.FC = () => {
  const { isAffiliatedSession, currentLegalEntity, currentOrganization } =
    useSession<FrontendSession>()
  const generatePath = useFrontendPathGenerator()
  const { history } = useRouter()
  const operatorBillingPlan = useOperatorBillingPlan()

  const onClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()

      if (!currentOrganization) return

      // The history.replace below this conditional pops a modal that is specifically designed for inviting someone to
      // the organization as a managed operator. However, when a PLG user invites a team member, the invitation should
      // result in assigning them as an organization admin. Currently, this functionality is only supported through the
      // Invite modal located in the Team Members tab within Settings. As a temporary solution, Product has requested
      // the Invite Colleague button on the dashboard be a deep link to the Invite modal in Settings.
      if (operatorBillingPlan.hasBillingPlan) {
        history.push(routes.addTeamMember.generate({ leSlug: currentLegalEntity.slug }))
        return
      }

      history.replace(
        generatePath(routes.legalEntityHome, {
          leSlug: currentLegalEntity.slug,
        }),
        // Set the location state to trigger the invite clients modal
        { showInviteClientsModal: InviteClientsModalState.Initial }
      )
    },
    [currentLegalEntity.slug, currentOrganization, generatePath, history, operatorBillingPlan]
  )

  const noun = isAffiliatedSession ? "Client" : "Colleague"

  return (
    <RequiresCreate
      source={FrontendPermissionSource.LegalEntity}
      module={FrontendPermissionModule.Invitations}
      allowDev={false}
    >
      <DigitsButton onClick={onClick} $variant="secondary-dark">
        <AddIcon />
        Invite {noun}
      </DigitsButton>
    </RequiresCreate>
  )
}
