export const internalUserPreferenceKeys = {
  animationConfigDevTools: "acdt",
  reportFileSourceDevTools: "rfsdt",
  gridDevTools: "gdt",
  hideInviteAccountantToast: "hide-invite-accountant-toast",
  viewedAssistant: "viewed-assistant",
  reportDocViewer: "rdv",
  vendorDirectory: "vendor-directory",
  graphqlNoBatching: "graphql-no-batching",
  showStatementSummaries: "statement-llm",
  showTransactionActionItems: "show-transaction-action-items",
  inbox: "inbox",
}

export const userPreferenceKeys = {
  expandedView: "expanded-view",
}
