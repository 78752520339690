import * as React from "react"
import { LayoutComponentType, ProviderType } from "@digits-graphql/frontend/graphql-bearer"
import { displayNameForDocumentKind } from "src/frontend/components/OS/Applications/Reports/Browser/Generator/shared"
import { StatementSize } from "src/frontend/components/OS/Applications/Reports/Report/Components/Statements/shared"
import { Statement } from "src/frontend/components/OS/Applications/Reports/Report/Components/Statements/Statement"
import { ComponentExpandLink } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentExpandIcon"
import { ComponentSummary } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { type ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useConfigOriginOverride } from "src/frontend/components/Shared/Layout/hooks/useConfigOriginOverride"
import { useComponentDetailsLinkPath } from "src/frontend/components/Shared/Layout/hooks/useEntityDetailsViewPaths"
import {
  type MatchedComponent,
  type SizingProps,
} from "src/frontend/components/Shared/Layout/types"
import { usePortalStatementLiveData } from "src/frontend/components/Shared/Portals/hooks/usePortalStatementLiveData"
import { useDataSourcePreference } from "src/frontend/hooks/useDataSourcePreference"
import useIntervalOrigin from "src/shared/hooks/useIntervalOrigin"

/*
  STYLES
*/

/*
  INTERFACES
*/

type Props = SizingProps & {
  component: MatchedComponent<"statement">
  componentSize: ComponentSize
}

/*
  COMPONENTS
*/

export const StatementComponent: React.FC<Props> = ({ component, componentSize }) => {
  // Historically, some portal components were archived with intervalCount 12.
  // Statements interpret intervalCount as the number of interval to show in the
  // "primary" column, which is not what we want to show on the main portal when unspecified.
  //  We don't want to override the value provided from the TimeContext either
  //  so that details pages display correctly.
  const origin = useIntervalOrigin()
  const config = useConfigOriginOverride(component.config.statement, origin.intervalCount ?? 1)
  const statementPath = useComponentDetailsLinkPath(component.config.statement.kind, origin)

  const { statement, loading } = usePortalStatementLiveData(config, LayoutComponentType.Statement)
  const statementSize = componentSize.isSmall ? StatementSize.Condensed : StatementSize.Full
  const dataSourcePreference = useDataSourcePreference(ProviderType.QuickBooks)

  const title = React.useMemo(
    () => (
      <ComponentExpandLink to={statementPath} componentSize={componentSize}>
        {displayNameForDocumentKind(component.config.statement.kind, dataSourcePreference?.taxForm)}
      </ComponentExpandLink>
    ),
    [component.config.statement.kind, componentSize, dataSourcePreference?.taxForm, statementPath]
  )

  return (
    <>
      <ComponentSummary
        css="margin-bottom: 0"
        title={title}
        componentSize={componentSize}
        timeseries={undefined}
      />
      <Statement statement={statement} size={statementSize} loading={loading} />
    </>
  )
}
