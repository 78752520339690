import * as React from "react"
import { ObjectKind } from "@digits-graphql/frontend/graphql-bearer"
import { type SizingProps } from "src/frontend/components/Shared/Layout/types"
import { type TopEntitiesProps } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Lists/shared"
import { TopCategoriesConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Lists/TopCategoriesConfig"
import { TopPartiesConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Lists/TopPartiesConfig"

export const TopEntitiesConfig: React.FC<SizingProps & TopEntitiesProps> = ({ config }) => {
  if (config.topEntities.kind === ObjectKind.Category) {
    return <TopCategoriesConfig config={config} />
  }

  if (config.topEntities.kind === ObjectKind.Party) {
    return <TopPartiesConfig config={config} />
  }

  return null
}
