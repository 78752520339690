import * as React from "react"
import { Amount } from "@digits-shared/components/UI/Table/Content"
import styled from "styled-components"
import {
  Date,
  Description,
  Title as RowTitle,
  TopTransactionsList,
} from "src/frontend/components/OS/Applications/Reports/Report/Viewer/Layout/Shared/TopTransactionsList"
import { ComponentHeaderTitle } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { useComponentConfigTitle } from "src/frontend/components/Shared/Layout/Components/useComponentTitle"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { type MatchedConfig, type SizingProps } from "src/frontend/components/Shared/Layout/types"
import { useRemoveConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/ComponentConfigContext"
import { useTopTransactionsLiveData } from "src/frontend/components/Shared/Portals/hooks/useTopTransactionsLiveData"

const StyledTopTransactionsList = styled(TopTransactionsList)`
  ${RowTitle}, ${Amount} {
    color: #0c404b;
  }

  ${Description} {
    color: #0c404b99;
  }

  ${Date} {
    color: #0c404b99;
  }
`

/*
  COMPONENTS
*/

export const TopTransactionsConfig: React.FC<
  SizingProps & {
    config: MatchedConfig<"topTransactions">
  }
> = ({ config }) => {
  const transactionsConfig = config.topTransactions
  const removeConfig = useRemoveConfig()
  const { title } = useComponentConfigTitle(config)

  const { data, loading } = useTopTransactionsLiveData(transactionsConfig, config.type)

  // remove this component if the results are empty
  React.useEffect(() => {
    if (!loading && !data?.transactions?.length) {
      removeConfig()
    }
  }, [data, loading, removeConfig])

  return (
    <>
      <ComponentHeaderTitle
        title={title}
        componentSize={ComponentSize.Small}
        periodName={undefined}
      />
      <StyledTopTransactionsList padding={10} transactions={data?.transactions} loading={loading} />
    </>
  )
}
