import * as React from "react"
import { useComponentContext } from "src/frontend/components/OS/Applications/Reports/Report/Viewer/Layout/LayoutViewer/ComponentContext"
import { matchConfigWithOrigin } from "src/frontend/components/Shared/Layout/types"

export function useComponentIntervalOrigin(customIntervalCount?: number) {
  const { component } = useComponentContext()

  return React.useMemo(() => {
    const { origin } = matchConfigWithOrigin(component.config)
    if (!origin) return undefined

    const { year, interval, index } = origin
    let intervalCount = customIntervalCount
    if (!intervalCount && origin.intervalCount && origin.intervalCount > 1) {
      intervalCount = origin.intervalCount
    }

    return origin ? { year, interval, index, intervalCount } : undefined
  }, [component.config, customIntervalCount])
}
