import React from "react"
import { useEvent } from "react-use"
import { CategoryState, ObjectKind } from "@digits-graphql/frontend/graphql-bearer"
import { LoadingBlock, LoadingCircle } from "@digits-shared/components/Loaders"
import { svgIconStyles, svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgXCircle } from "@digits-shared/components/SVGIcons/line/XCircle.svg"
import { SvgStars01Solid } from "@digits-shared/components/SVGIcons/solid/Stars01Solid.svg"
import { ColorIcon } from "@digits-shared/components/UI/Elements/ColorIcon"
import { Keys } from "@digits-shared/components/UI/Elements/Keys"
import { LazyComponentLoad } from "@digits-shared/components/UI/Elements/LazyComponentLoad"
import { DigitsTooltip } from "@digits-shared/DesignSystem/Tooltip"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import { themedStyles, themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import fonts, { BodyText, DetailText, LabelText } from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { useTextIsOverflowing } from "src/frontend/hooks/useTextIsOverflowing"
import { SVGIconComponent } from "src/shared/components/Icons/SVGIcon"
import { PartyIcon } from "src/shared/components/PartyHover/PartyIcon"
import { AddNewResult } from "src/shared/components/Typeahead/AddNewResult"
import {
  type CategoryTypeaheadProps,
  isCategoryTypeAhead,
  isPartyTypeAhead,
  isPartyWithNewTypeAhead,
  isTypeaheadNewParty,
  isTypeaheadOrganization,
  isUserTypeAhead,
  isUserWithOrgTypeAhead,
  newPartyEntityResultWithId,
  type OnSelectedCallback,
  type PartyTypeaheadProps,
  type TypeaheadResult,
  type UserTypeaheadProps,
} from "src/shared/components/Typeahead/sharedTypeahead"
import { useTypeaheadCategories } from "src/shared/components/Typeahead/useTypeaheadCategories"
import { useTypeaheadOrganizations } from "src/shared/components/Typeahead/useTypeaheadOrganizations"
import { useTypeaheadParties } from "src/shared/components/Typeahead/useTypeaheadParties"
import { useTypeaheadUsers } from "src/shared/components/Typeahead/useTypeaheadUsers"

const TYPEAHEAD_ID_PREFIX = "typeahead-section-result"

/*
 STYLES
*/

const Results = styled.div`
  max-height: 275px;
  overflow-y: auto;
`

const NoResults = styled(DetailText)`
  color: ${colors.translucentSecondary80};
  padding: 10px 12px;
  margin-bottom: 10px;
  text-align: center;
`

const Info = styled.div`
  flex: 1;
  overflow: hidden;
  text-align: left;
`

const iconStyles = themedStyles({
  light: css`
    background: none;
    border: 1px solid transparent;
    ${svgPathStyles(colors.secondary80, 1.5)};

    &.suggested {
      ${svgPathStyles(colors.aiBlue, 1.5)};
    }
  `,
  dark: css`
    background: rgba(18, 53, 63, 0.7);
    border: 1px solid rgba(22, 232, 236, 0.2);
    ${svgPathStyles(colors.translucentWhite80, 1.5)};
  `,
})

const CategoryIcon = styled(SVGIconComponent)`
  ${iconStyles};
  height: 35px;
  width: 35px;
  padding: 5px;
`

const titleColor = themedValue({
  light: colors.secondary,
  dark: colors.white,
})

const TitleContainer = styled.div<{ isTextOverflowing: boolean; isHovered: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ isTextOverflowing, isHovered }) =>
    isTextOverflowing &&
    css`
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 100%;
        background: linear-gradient(
          to right,
          ${colors.transparent},
          ${isHovered ? colors.white : colors.secondary05}
        );
        z-index: 2;
      }
    `}
`
const StyledTitle = styled(BodyText)`
  color: ${titleColor};
  font-weight: ${fonts.weight.medium};
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledTooltip = styled(DigitsTooltip)`
  width: 100%;
`

const subtitleColor = themedValue({
  light: colors.translucentSecondary80,
  dark: colors.translucentWhite30,
})

const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  pointer-events: none;
`

const Subtitle = styled(LabelText)`
  color: ${subtitleColor};
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
`

const itemStyles = themedStyles({
  light: css`
    &.selected:not(.current),
    &:hover:not(.selected) {
      background: ${colors.white};
    }

    &.current {
      background: ${colors.white};

      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        border-top: 1px solid ${colors.translucentBlack10};
        border-bottom: 1px solid ${colors.translucentBlack10};
        pointer-events: none;
      }
    }

    &.suggested {
      border-radius: 4px;
      margin: 8px 12px;
      border: 1px solid ${colors.aiBlue};
      background: ${colors.gradients.ai10};

      ${StyledTitle} {
        transition: color 0.1s ease;
        color: ${colors.aiBlue};
      }

      &.selected,
      &.current,
      &:hover:not(.selected) {
        background: ${colors.gradients.ai30};
      }
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  `,
  dark: undefined,
})

const Item = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  white-space: nowrap;
  padding: 7px 12px;
  cursor: pointer;

  ${itemStyles};

  &:last-child {
    margin-bottom: 10px;
  }
`

const NewLabel = styled(LabelText)`
  color: ${colors.white};
  font-weight: ${fonts.weight.heavy};
  background: ${colors.primary80};
  border-radius: 4px;
  padding: 2px 8px;
`

const ClearButton = styled(DigitsTooltip)`
  margin-right: 5px;
`

const ClearIcon = styled(SvgXCircle)`
  ${svgPathStyles(colors.secondary, 1.5)};
  cursor: pointer;
  width: 20px;
  height: 20px;
  opacity: 0.5;
  display: block;

  &:hover {
    opacity: 1;
  }
`

const SuggestedIcon = styled(SvgStars01Solid)`
  width: 12px;
  height: 12px;
  ${svgIconStyles(colors.aiBlue)};
`

const SuggestedLabel = styled(DetailText)`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 12px;
  color: ${colors.aiBlue};
`

const AllLabel = styled(DetailText)`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 12px 12px 8px;
  color: ${colors.secondary};
`

/*
 INTERFACES
*/
interface SearchProps {
  searchTerm: string
  onResultSelected: OnSelectedCallback
  onClear?: () => void
  currentValue?: string
  close: () => void
}

interface SearchResults extends SearchProps {
  kind: ObjectKind
  results: TypeaheadResult[]
  suggestedResults?: TypeaheadResult[]
  loading: boolean
  allowAddNew?: boolean
}

interface ResultProps {
  id: string
  data: TypeaheadResult
  selected: boolean
  current: boolean
  onResultClick: (data: TypeaheadResult, event: React.MouseEvent) => void
  onClear?: () => void
}

/*
 COMPONENTS
*/

export const TypeaheadSearch: React.FC<
  SearchProps & (CategoryTypeaheadProps | PartyTypeaheadProps | UserTypeaheadProps)
> = (props) => {
  const { searchTerm } = props
  if (isCategoryTypeAhead(props)) return <CategoryTypeahead {...props} searchTerm={searchTerm} />

  if (isPartyTypeAhead(props)) return <PartyTypeahead {...props} searchTerm={searchTerm} />

  if (isUserTypeAhead(props)) return <UsersTypeahead {...props} searchTerm={searchTerm} />
}

const CategoryTypeahead: React.FC<SearchProps & CategoryTypeaheadProps> = ({
  searchTerm,
  kind,
  includedTypes,
  includedSubtypes,
  includedStates = [CategoryState.CategoryActive],
  enableSelectParentCategory = true,
  enableSelectRootCategory = false,
  suggestedCategories,
  ...rest
}) => {
  const { results, suggestedResults, loading } = useTypeaheadCategories({
    text: searchTerm,
    includedTypes,
    includedSubtypes,
    includedStates,
    suggestedCategories,
    enableSelectParentCategory,
    enableSelectRootCategory,
  })

  return (
    <TypeaheadSearchResults
      results={results}
      suggestedResults={suggestedResults}
      loading={loading}
      searchTerm={searchTerm}
      kind={kind}
      {...rest}
    />
  )
}

const PartyTypeahead: React.FC<SearchProps & PartyTypeaheadProps> = (props) => {
  const { searchTerm, kind, ...rest } = props
  const { newEntities = [] } = isPartyWithNewTypeAhead(props) ? props : {}
  const { results, loading } = useTypeaheadParties(searchTerm, kind, newEntities)
  return (
    <TypeaheadSearchResults
      results={results}
      loading={loading}
      searchTerm={searchTerm}
      kind={kind}
      {...rest}
    />
  )
}

const UsersTypeahead: React.FC<SearchProps & UserTypeaheadProps> = (props) => {
  const { searchTerm, objectIdentifier, includeCurrentUser, ...rest } = props
  const { results: userResults, loading } = useTypeaheadUsers(
    searchTerm,
    objectIdentifier,
    includeCurrentUser
  )

  const { includeOrganization = false } = isUserWithOrgTypeAhead(props) ? props : {}
  const { results: orgResult } = useTypeaheadOrganizations(searchTerm)

  const results = React.useMemo(
    () => (includeOrganization ? orgResult.concat(userResults) : userResults),
    [includeOrganization, orgResult, userResults]
  )

  return (
    <TypeaheadSearchResults results={results} loading={loading} searchTerm={searchTerm} {...rest} />
  )
}

const TypeaheadSearchResults: React.FC<SearchResults> = ({
  results,
  suggestedResults = [],
  loading,
  searchTerm,
  kind,
  onResultSelected,
  currentValue,
  allowAddNew = false,
  onClear,
  close,
}) => {
  const query = searchTerm.trim().toLowerCase()
  const data = React.useMemo(
    () =>
      query
        ? results.filter((d) => d.searchValues.join(" ").toLowerCase().includes(query))
        : results,
    [results, query]
  )

  const suggestedData = React.useMemo(
    () =>
      query
        ? suggestedResults?.filter((d) => d.searchValues.join(" ").toLowerCase().includes(query))
        : suggestedResults,
    [suggestedResults, query]
  )

  const currentIndex = currentValue ? data.findIndex((d) => d.id === currentValue) : -1
  const [activeIndex, setActiveIndex] = React.useState(currentIndex)
  const canAddNew = useCanAddNew(data, searchTerm, allowAddNew, currentValue)
  const totalResults = data.length + suggestedData.length
  const show = totalResults > 0
  const indexOffset = suggestedData.length

  const onResultClick = React.useCallback(
    (result: TypeaheadResult, event: React.MouseEvent) => {
      event.stopPropagation()
      onResultSelected(result)
    },
    [onResultSelected]
  )

  const onKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      const { code, shiftKey } = event

      if (shiftKey && code === Keys.Enter && allowAddNew) {
        event.preventDefault()
        event.stopPropagation()

        onResultSelected(newPartyEntityResultWithId(searchTerm.trim()))
        return
      }

      if (shiftKey && code === Keys.Delete && currentValue) {
        event.preventDefault()
        event.stopPropagation()
        if (onClear) {
          onClear()
        }
        return
      }

      // Adjust the index to the correct range if there is an index offset (i.e. we have suggested items)
      // and use that index to retrieve the active item from the correct data list
      const index = activeIndex >= indexOffset ? activeIndex - indexOffset : activeIndex
      const activeItem = activeIndex >= indexOffset ? data[index] : suggestedData[index]

      switch (code) {
        case Keys.Enter:
          if (activeItem) {
            event.preventDefault()
            event.stopPropagation()
            onResultSelected(activeItem)
          }
          break

        case Keys.Tab:
          if (activeItem) {
            event.preventDefault()
            event.stopPropagation()
            onResultSelected(activeItem)
            break
          }

          // If the user tabs out of the typeahead, we want to close it by passing an empty entity
          close()
          break

        case Keys.ArrowUp:
          event.preventDefault()
          event.stopPropagation()
          if (activeIndex > 0) {
            const newIndex = activeIndex - 1
            setActiveIndex(newIndex)
            document.querySelector(`#${TYPEAHEAD_ID_PREFIX}-${newIndex}`)?.scrollIntoView({
              behavior: "smooth",
            })
          }
          break

        case Keys.ArrowDown:
          event.preventDefault()
          event.stopPropagation()
          if (activeIndex < totalResults - 1) {
            const newIndex = activeIndex + 1
            setActiveIndex(newIndex)
            document.querySelector(`#${TYPEAHEAD_ID_PREFIX}-${newIndex}`)?.scrollIntoView({
              behavior: "smooth",
            })
          }
          break

        case Keys.PageUp: {
          event.preventDefault()
          event.stopPropagation()
          const newIndex = activeIndex >= 10 ? activeIndex - 10 : 0

          setActiveIndex(newIndex)
          document.querySelector(`#${TYPEAHEAD_ID_PREFIX}-${newIndex}`)?.scrollIntoView({
            behavior: "smooth",
          })
          break
        }

        case Keys.PageDown: {
          event.preventDefault()
          event.stopPropagation()
          const newIndex = activeIndex <= totalResults - 10 ? activeIndex + 10 : totalResults - 1

          setActiveIndex(newIndex)
          document.querySelector(`#${TYPEAHEAD_ID_PREFIX}-${newIndex}`)?.scrollIntoView({
            behavior: "smooth",
          })
          break
        }

        // Escape key should close the typeahead
        case Keys.Escape: {
          event.preventDefault()
          event.stopPropagation()
          close()
          break
        }

        default:
          if (totalResults === 1 || activeIndex === -1) {
            return setActiveIndex(0)
          }
          if (totalResults > 1 && activeIndex >= totalResults) {
            setActiveIndex(totalResults - 1)
          }
          break
      }
    },
    [
      allowAddNew,
      currentValue,
      activeIndex,
      indexOffset,
      data,
      suggestedData,
      onResultSelected,
      searchTerm,
      onClear,
      close,
      totalResults,
    ]
  )
  // Keydown events are only being captured window and { capture: true } are passed in as options
  // for the typeahead positioned via a portal
  useEvent("keydown", onKeyDown, undefined, { capture: true })

  if (loading) {
    return (
      <Results>
        <ResultLoading />
        <ResultLoading />
        <ResultLoading />
      </Results>
    )
  }

  if (!show) {
    if (searchTerm.trim().length === 0) {
      return null
    }

    if (canAddNew) {
      return <AddNewResult searchTerm={searchTerm} onResultSelected={onResultSelected} />
    }

    return <NoResults>No {kind.toLowerCase()} found</NoResults>
  }

  return (
    <>
      <Results>
        {suggestedData.length > 0 && (
          <>
            <SuggestedLabel>
              <SuggestedIcon />
              Suggestions
            </SuggestedLabel>

            {suggestedData.map((r, idx) => (
              <Result
                key={`${r.id}-suggested-${idx}`}
                data={r}
                onResultClick={onResultClick}
                onClear={onClear}
                selected={idx === activeIndex}
                current={idx === currentIndex}
                // ID is used for keyboard navigation, to allow scrolling to the selected item
                id={`${TYPEAHEAD_ID_PREFIX}-${idx}`}
              />
            ))}

            <AllLabel>All Results</AllLabel>
          </>
        )}

        {data.map((r, idx) => (
          <Result
            key={r.id}
            data={r}
            onResultClick={onResultClick}
            onClear={onClear}
            selected={idx + indexOffset === activeIndex}
            current={idx + indexOffset === currentIndex}
            // ID is used for keyboard navigation, to allow scrolling to the selected item
            id={`${TYPEAHEAD_ID_PREFIX}-${idx + indexOffset}`}
          />
        ))}
      </Results>
      {canAddNew && <AddNewResult searchTerm={searchTerm} onResultSelected={onResultSelected} />}
    </>
  )
}

export const ResultLoading: React.FC = () => (
  <Item>
    <LoadingCircle width="35px" height="35px" animationPlayState="paused" />

    <Info>
      <TitleContainer isTextOverflowing={false} isHovered={false}>
        <LoadingBlock width="250px" height="14px" />
      </TitleContainer>
      <SubtitleContainer>
        <LoadingBlock width="150px" height="10px" margin="5px 0 0" animationPlayState="paused" />
      </SubtitleContainer>
    </Info>
  </Item>
)

const Result: React.FC<ResultProps> = ({ data, onResultClick, selected, current, id, onClear }) => {
  const { value: isHovered, setTrue: setEnter, setFalse: setLeave } = useStateBoolean()
  const depth = data.depth || 0
  const selectable = data.isSelectable === undefined || data.isSelectable
  const classes = []
  if (!selectable) classes.push("disabled")
  if (selected) classes.push("selected")
  if (current) classes.push("current")
  if (data.isSuggestion) classes.push("suggested")

  return (
    <Item
      id={id}
      className={classes.join(" ")}
      onMouseDown={selectable ? onResultClick.bind(undefined, data) : undefined}
      onMouseEnter={selectable ? setEnter : undefined}
      onMouseLeave={selectable ? setLeave : undefined}
      style={{ paddingLeft: depth * 15 + 12 }}
    >
      <LazyComponentLoad
        placeholder={<LoadingCircle width="35px" height="35px" animationPlayState="paused" />}
      >
        <ResultIcon data={data} />
      </LazyComponentLoad>

      <Info>
        <Title title={data.title} isHovered={isHovered || selected} />
        {data.subtitle && (
          <SubtitleContainer>
            <Subtitle>{data.subtitle}</Subtitle>
          </SubtitleContainer>
        )}
      </Info>

      {isTypeaheadNewParty(data.entity) && !(current && onClear) && <NewLabel>New</NewLabel>}
      {current && onClear && <Clear onClear={onClear} />}
    </Item>
  )
}

const Title: React.FC<{ title: string; isHovered: boolean }> = ({ title, isHovered }) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const { textElementRef, isTextOverflowing } = useTextIsOverflowing<HTMLDivElement>()

  return (
    <TitleContainer ref={ref} isTextOverflowing={isTextOverflowing} isHovered={isHovered}>
      <StyledTooltip content={title} disabled={!isTextOverflowing}>
        <StyledTitle ref={textElementRef}>{title}</StyledTitle>
      </StyledTooltip>
    </TitleContainer>
  )
}

const Clear: React.FC<{ onClear: () => void }> = ({ onClear }) => {
  const onClearResult = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      event.preventDefault()
      onClear?.()
    },
    [onClear]
  )

  return (
    <ClearButton content="Remove">
      <ClearIcon onMouseDown={onClearResult} />
    </ClearButton>
  )
}

const ResultIcon: React.FC<{ data: TypeaheadResult }> = ({ data }) => {
  switch (data.kind) {
    case ObjectKind.Category:
      return (
        <CategoryIcon
          subjectDisplayKey={data.entity.displayKey || ""}
          className={data.isSuggestion ? "suggested" : ""}
        />
      )

    case ObjectKind.Party:
      return <PartyIcon party={data.entity} />

    case ObjectKind.User:
      if (isTypeaheadOrganization(data.entity)) {
        return <ColorIcon fallbackText={data.entity.name} imageUrl={data.entity.iconUrl} />
      }

      return (
        <ColorIcon
          fallbackUser={data.entity}
          imageUrl={data.entity.avatarUrl}
          fallbackText={data.title}
        />
      )
  }
}

const useCanAddNew = (
  dataSet: TypeaheadResult[],
  searchTerm: string,
  allowAddNew: boolean,
  defaultValue: string | undefined
) =>
  React.useMemo(
    () =>
      allowAddNew &&
      searchTerm.trim().length > 0 &&
      searchTerm.toLowerCase() !== defaultValue?.toLowerCase() &&
      dataSet.every((item) => item.title.toLowerCase() !== searchTerm.trim().toLowerCase()),
    [allowAddNew, dataSet, defaultValue, searchTerm]
  )
