import React from "react"
import { isNetworkRequestInFlight, type NetworkStatus } from "@apollo/client/core/networkStatus"
import {
  type Category,
  CategoryState,
  type CategorySubtype,
  type CategoryType,
  type EntityCategory,
  ObjectKind,
  useListCategoriesQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { defined } from "@digits-shared/helpers/filters"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { type CategoryTypeaheadResult } from "src/shared/components/Typeahead/sharedTypeahead"
import {
  convertHierarchicalDimensionTreeToFlatList,
  convertToHierarchicalDimensionTree,
  getHierarchicalDimensionAncestors,
  type HierarchicalDimensionNode,
} from "src/shared/helpers/hierarchicalDimensionHelper"

interface TypeaheadCategoriesProps {
  text: string
  includedTypes?: CategoryType[]
  includedSubtypes?: CategorySubtype[]
  includedStates?: CategoryState[]
  enableSelectRootCategory?: boolean
  enableSelectParentCategory?: boolean
  suggestedCategories?: EntityCategory[]
}

export function useTypeaheadCategories({
  text,
  includedTypes,
  includedSubtypes,
  includedStates,
  enableSelectParentCategory,
  enableSelectRootCategory,
  suggestedCategories,
}: TypeaheadCategoriesProps) {
  const { result, networkStatus } = useCategoriesList()

  const categories = React.useMemo(
    () =>
      buildCategoryResults({
        categories: result,
        includedTypes,
        includedSubtypes,
        includedStates,
        enableSelectParentCategory,
        enableSelectRootCategory,
        text,
      }),
    [
      enableSelectParentCategory,
      enableSelectRootCategory,
      includedStates,
      includedSubtypes,
      includedTypes,
      result,
      text,
    ]
  )

  const suggestedCategoryResults = React.useMemo(
    () => suggestedCategories?.map(suggestedCategoryToTypeaheadResult) ?? [],
    [suggestedCategories]
  )

  return React.useMemo(
    () => ({
      loading: isNetworkRequestInFlight(networkStatus),
      results: categories,
      suggestedResults: suggestedCategoryResults,
    }),
    [networkStatus, suggestedCategoryResults, categories]
  )
}

function useCategoriesList(): {
  result: Category[]
  networkStatus: NetworkStatus
} {
  const viewKey = useViewVersion()
  const response = useListCategoriesQuery({
    variables: { viewKey },
  })

  const { networkStatus } = response
  const result = response.loading ? response.previousData : response.data

  return {
    result: result?.listCategories || [],
    networkStatus,
  }
}

function buildAncestorMaps(flatTreeList: HierarchicalDimensionNode<Category>[]) {
  const categoryAncestors = new Map<string, EntityCategory[]>()
  const categoryIdToHierarchyText = new Map<string, string>()

  flatTreeList.forEach((node) => {
    const ancestorCategories = getHierarchicalDimensionAncestors(node).map((a) => a.dimension)
    const categoryId = node.dimension.id

    categoryAncestors.set(categoryId, ancestorCategories)
    categoryIdToHierarchyText.set(categoryId, ancestorCategories.map((anc) => anc.name).join(" ▸ "))
  })

  return { categoryAncestors, categoryIdToHierarchyText }
}

function categoryToTypeaheadResult({
  node,
  hierarchyText,
  enableSelectRootCategory = false,
  enableSelectParentCategory = false,
}: {
  node: HierarchicalDimensionNode<Category>
  hierarchyText: string | undefined
  enableSelectRootCategory?: boolean
  enableSelectParentCategory?: boolean
}): CategoryTypeaheadResult {
  const { dimension: category } = node
  const isRoot = !node.parent || !!category.synthesized
  const isParent = node.children.length > 0

  return {
    id: category.id,
    title: category.name,
    depth: category.ancestors?.length ?? 0,
    subtitle: [category.displayNumber, hierarchyText].filter(defined).join(" - "),
    searchValues: [category.name, category.displayNumber].filter(defined),
    kind: ObjectKind.Category,
    entity: category,
    isSelectable:
      (enableSelectRootCategory || !isRoot) && (enableSelectParentCategory || !isParent),
  }
}

function suggestedCategoryToTypeaheadResult(category: EntityCategory): CategoryTypeaheadResult {
  return {
    id: category.id,
    title: category.name,
    depth: 0,
    subtitle: category.chartOfAccountsDisplayNumber || undefined,
    searchValues: [category.name, category.chartOfAccountsDisplayNumber].filter(defined),
    kind: ObjectKind.Category,
    entity: category,
    isSelectable: true,
    isSuggestion: true,
  }
}

function buildCategoryResults({
  categories,
  includedTypes,
  includedSubtypes,
  includedStates,
  enableSelectParentCategory,
  enableSelectRootCategory,
}: { categories: Category[] } & TypeaheadCategoriesProps) {
  const categoryTree = convertToHierarchicalDimensionTree(categories, 0)
  const flatTreeList = convertHierarchicalDimensionTreeToFlatList<Category>(categoryTree)
  const { categoryIdToHierarchyText } = buildAncestorMaps(flatTreeList)

  const isIncludedType = (category: Category) =>
    !includedTypes || includedTypes.includes(category.type)

  const isIncludedSubtype = (category: Category) =>
    !includedSubtypes || includedSubtypes.includes(category.subtype)

  const isIncludedState = (category: Category) =>
    !includedStates ||
    includedStates.includes(category.categoryState) ||
    (includedStates.includes(CategoryState.CategoryActive) && category.active) ||
    (includedStates.includes(CategoryState.CategoryInactive) && !category.active)

  return flatTreeList
    .filter(
      ({ dimension: category }) =>
        isIncludedType(category) && isIncludedSubtype(category) && isIncludedState(category)
    )
    .map((node) =>
      categoryToTypeaheadResult({
        node,
        hierarchyText: categoryIdToHierarchyText.get(node.dimension.id),
        enableSelectParentCategory,
        enableSelectRootCategory,
      })
    )
}
