import * as React from "react"
import { DeltaChevron, DeltaContainer } from "@digits-shared/components/UI/Elements/Delta"
import { Amount } from "@digits-shared/components/UI/Table/Content"
import styled from "styled-components"
import {
  Categories,
  TopPartiesList,
} from "src/frontend/components/OS/Applications/Reports/Report/Viewer/Layout/Shared/TopPartiesList"
import { ComponentHeaderTitle } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { useComponentConfigTitle } from "src/frontend/components/Shared/Layout/Components/useComponentTitle"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useRemoveConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/ComponentConfigContext"
import { type TopEntitiesProps } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Lists/shared"
import { useTopPartiesLiveData } from "src/frontend/components/Shared/Portals/hooks/useTopPartiesLiveData"
import { type PartyTransactionSummary } from "src/frontend/types"

const StyledTopPartiesList = styled(TopPartiesList)`
  ${Categories} {
    color: #0c404b99;
  }

  ${Amount} {
    color: #0c404b;
  }

  ${DeltaContainer} {
    color: #0c404b99;

    ${DeltaChevron} {
      border-bottom-color: #0c404b99;
      border-top-color: #0c404b99;
    }
  }
`

/*
  COMPONENTS
*/

export const TopPartiesConfig: React.FC<TopEntitiesProps> = ({ config }) => {
  const partiesConfig = config.topEntities
  const removeConfig = useRemoveConfig()
  const { title } = useComponentConfigTitle(config)

  const { data, loading } = useTopPartiesLiveData(partiesConfig, config.type)

  const partySummaries: PartyTransactionSummary[] | undefined = React.useMemo(() => {
    if (!data) return undefined

    return data.entities.party.map((p) => ({
      party: p.partyObject,
      summary: p.summary,
      categoryNames: p.by.category.map((c) => c.categoryObject.name),
    }))
  }, [data])

  // remove this component if the results are empty
  React.useEffect(() => {
    if (partySummaries && !partySummaries?.length) {
      removeConfig()
    }
  }, [partySummaries, removeConfig])

  return (
    <>
      <ComponentHeaderTitle
        title={title}
        componentSize={ComponentSize.Small}
        periodName={undefined}
      />
      <StyledTopPartiesList padding={10} partySummaries={partySummaries} loading={loading} />
    </>
  )
}
