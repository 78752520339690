import * as React from "react"
import {
  type CompanyFinancialValue,
  type InstitutionProduct,
  type QuotePackageFieldsFragment,
} from "@digits-graphql/frontend/graphql-bearer"
import { type PlgOnboardAction } from "src/frontend/components/Onboard/PLG/Context/plgOnboardingReducer"

export enum PlgOnboardingStep {
  InitialCheckpoint = "InitialCheckpoint",
  Redirect = "Redirect",
  ContactSupport = "ContactSupport",
  CreateYourAccount = "CreateYourAccount",
  HoldForEmailVerification = "HoldForEmailVerification",
  ChooseYourSoftware = "ChooseYourSoftware",
  ChosenSoftware = "ChosenSoftware",
  CompanyInfo = "CompanyInfo",
  PaymentSetup = "PaymentSetup",
  ConnectBanks = "ConnectBanks",
  ConnectPayroll = "ConnectPayroll",
  ActivateAccount = "ActivateAccount",
  OnboardingComplete = "OnboardingComplete",
  // zombie steps
  AdditionalCompanyInfo = "AdditionalCompanyInfo",
  BanksAndCards = "BanksAndCards",
  PayrollAndRevenue = "PayrollAndRevenue",
}

export enum PlgAnimationState {
  NOT_STARTED = "NOT_STARTED",
  ANIMATING_IN = "ANIMATING_IN",
  PAUSE = "PAUSE",
  ANIMATING_OUT = "ANIMATING_OUT",
  COMPLETE = "COMPLETE",
}

export enum Subscription {
  AIBookkeeping = "AIBookkeeping",
  TaxReadyBooks = "TaxReadyBooks",
  MonthlyAccounting = "MonthlyAccounting",
  AccountingTaxes = "AccountingTaxes",
}

export enum Software {
  Digits = "Digits",
  QuickBooks = "QuickBooks",
  QuickBooksDesktop = "QuickBooksDesktop",
  Bench = "Bench",
  Pilot = "Pilot",
  Fondo = "Fondo",
  Xero = "Xero",
  Excel = "Excel",
  Other = "Other",
  Unknown = "Unknown",
}

export type User = { givenName: string; familyName: string }

export type Financials = Record<InstitutionProduct, CompanyFinancialValue[]>

export type Preferences = {
  cutoverDate: number | null
  hasQbo?: boolean | null
  software: Software
}

export interface PlgOnboardingContextValue {
  state: PlgOnboardState
  dispatch: React.Dispatch<PlgOnboardAction>
  setStep: (step: PlgOnboardingStep) => void
  startLoading: () => void
  stopLoading: () => void
}

export interface PlgOnboardState {
  step: PlgOnboardingStep
  back: boolean
  user: User | null
  verificationEmail: string | null
  loading: boolean
  animation: PlgAnimationState
  animating: boolean
  financials: Partial<Financials>
  quotePackage: QuotePackageFieldsFragment | null
  preferences: Preferences | null
  plaidClosed: boolean
}

export const DEFAULT_STATE: PlgOnboardState = {
  step: PlgOnboardingStep.InitialCheckpoint,
  back: false,
  user: null,
  verificationEmail: null,
  loading: false,
  animation: PlgAnimationState.NOT_STARTED,
  animating: false,
  financials: {},
  quotePackage: null,
  preferences: null,
  plaidClosed: false,
}

export const PlgOnboardingContext = React.createContext<PlgOnboardingContextValue>({
  state: DEFAULT_STATE,
  dispatch: () => {},
  setStep: () => {},
  startLoading: () => {},
  stopLoading: () => {},
})

export const usePlgOnboarding = (): PlgOnboardingContextValue => {
  const value = React.useContext(PlgOnboardingContext)

  if (!value) {
    throw new Error("usePlgOnboarding must be called within a PlgOnboardingContextProvider")
  }

  return value
}
