import { BillingProductName } from "@digits-graphql/frontend/graphql-bearer"
import { useAffiliateOrganizationBillingPlan } from "src/frontend/components/OS/Applications/Settings/AffiliateOrganizationBillingPlanContext"

// Check against current plan w/ valid billing plans in order to display/block billing related components & routes
export function useHasBillablePlan() {
  const VALID_BILLING_PLANS = [
    BillingProductName.Free,
    BillingProductName.Growth,
    BillingProductName.Premium,
  ]
  const { planName, loading } = useAffiliateOrganizationBillingPlan()

  return { isBillablePlan: VALID_BILLING_PLANS.includes(planName), loading }
}
