import { ReportKind, type TaxForm } from "@digits-graphql/frontend/graphql-bearer"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import {
  reportKindDisplayName,
  type ReportPackageDocumentKind,
} from "src/frontend/components/OS/Applications/Reports/Packages/shared"

export function supportedReportPackageDocumentKinds(): ReportPackageDocumentKind[] {
  return ["Layout", ReportKind.ProfitAndLoss, ReportKind.BalanceSheet, ReportKind.CashFlow]
}

export const GenerationOption = styled.div<{
  isSelected: boolean
  alreadyIncluded: boolean
  isDisabled?: boolean
}>`
  position: relative;
  padding: 8px;
  border: 0.5px solid ${colors.translucentSecondary30};
  border-radius: ${borders.radius.card}px;
  display: flex;
  align-items: center;
  transition:
    border 200ms ease,
    box-shadow 200ms ease,
    opacity 200ms ease;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer;
    border: 0.5px solid ${colors.secondary};
    box-shadow:
      0 0 0 1px ${colors.translucentSecondary70},
      0 0 5px ${colors.translucentSecondary30};
  }

  ${({ isSelected, alreadyIncluded }) =>
    isSelected &&
    !alreadyIncluded &&
    css`
      & {
        border: 0.5px solid ${colors.primary};
        background-color: ${colors.white};
      }
    `}

  ${({ alreadyIncluded }) =>
    alreadyIncluded &&
    css`
      pointer-events: none;
      opacity: 0.5;
      border: none;
      box-shadow: none;
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`

export const GenerationOptionIconBackground = styled.div`
  width: 64px;
  height: 64px;
  background: linear-gradient(180deg, #00d3c7 0%, #00a69c 100%);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const GenerationOptionInfo = styled.div`
  flex: 1;
  margin: 0 20px;
`

export const GenerationOptionName = styled.div`
  font-weight: ${fonts.weight.heavy};
  font-size: 14px;
  line-height: 22px;
  color: ${colors.secondary};
`

export const GenerationOptionDescription = styled.div`
  font-size: 11px;
  line-height: 18px;
  color: ${colors.translucentSecondary80};
`

export function isReportKind(kind: ReportPackageDocumentKind): kind is ReportKind {
  return kind !== "ExecutiveSummary" && kind !== "Layout"
}

export function displayNameForDocumentKind(
  kind: ReportPackageDocumentKind,
  taxForm?: TaxForm
): string {
  switch (kind) {
    case "ExecutiveSummary":
    case "Layout":
      return "Executive Summary"
    case ReportKind.BalanceSheet:
    case ReportKind.ExpenseSummary:
    case ReportKind.CashFlow:
    case ReportKind.ProfitAndLoss:
    case ReportKind.Unknown:
      return reportKindDisplayName(kind, taxForm)
  }
}

export function iconForDocumentKind(kind: ReportPackageDocumentKind): string {
  switch (kind) {
    case "ExecutiveSummary":
    case "Layout":
      return require("static/images/reports/executive-summary-icon.png")
    case ReportKind.ExpenseSummary:
      return require("static/images/reports/executive-summary-icon.png")
    case ReportKind.BalanceSheet:
      return require("static/images/reports/balance-sheet-icon.png")
    case ReportKind.CashFlow:
      return require("static/images/reports/cash-flow-icon.png")
    case ReportKind.ProfitAndLoss:
      return require("static/images/reports/profit-and-loss-icon.png")
    case ReportKind.Unknown:
      return ""
  }
}

export function cssForDocumentKind(kind: ReportPackageDocumentKind) {
  switch (kind) {
    case "ExecutiveSummary":
    case "Layout":
      return css`
        width: 80px;
        height: 92px;
      `
    case ReportKind.ExpenseSummary:
      return css``
    case ReportKind.BalanceSheet:
      return css`
        width: 52px;
        height: 52px;
      `
    case ReportKind.CashFlow:
      return css`
        width: 44px;
        height: 36px;
        margin-top: 4px;
      `
    case ReportKind.ProfitAndLoss:
      return css`
        width: 44px;
        height: 33px;
        margin-top: 4px;
      `
    case ReportKind.Unknown:
      return css``
  }
}

export function descriptionForDocumentKind(kind: ReportPackageDocumentKind): string {
  switch (kind) {
    case "ExecutiveSummary":
      return "An intelligent summary of this business’s financial health"
    case "Layout":
      return "A customizable summary of this business’s financial health"
    case ReportKind.ExpenseSummary:
      return "In-depth breakdown of expenses across your business"
    case ReportKind.BalanceSheet:
      return "Breakdown of assets, liabilities, and shareholder equity"
    case ReportKind.CashFlow:
      return "Breakdown of cash and cash equivalents entering or leaving"
    case ReportKind.ProfitAndLoss:
      return "Breakdown of revenue, costs, and expenses incurred"
    case ReportKind.Unknown:
      return ""
  }
}
