import * as React from "react"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import { ColorIconStyled } from "@digits-shared/components/UI/Elements/ColorIcon"
import { IconSize } from "@digits-shared/components/UI/Icons/Icon"
import { Amount } from "@digits-shared/components/UI/Table/Content"
import { DigitsTooltip } from "@digits-shared/DesignSystem/Tooltip"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { useReportComponentIntervalOrigin } from "src/frontend/components/OS/Applications/Reports/Report/Viewer/Layout/hooks/useReportComponentIntervalOrigin"
import {
  type SummaryListData,
  TopList,
} from "src/frontend/components/OS/Applications/Reports/Report/Viewer/Layout/Shared/WidgetSummaryList"
import { DimensionTotalAmountWithDelta } from "src/frontend/components/OS/Shared/Dimensions/DimensionRow"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import { useTextIsOverflowing } from "src/frontend/hooks/useTextIsOverflowing"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"
import { type PartyTransactionSummary } from "src/frontend/types"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"
import { PartyIcon } from "src/shared/components/PartyHover/PartyIcon"
import { useStopClickPropagation } from "src/shared/hooks/useStopClickPropagation"

/*
  INTERFACES
*/

interface TopPartiesListProps {
  className?: string
  partySummaries: PartyTransactionSummary[] | undefined
  loading: boolean
  padding?: number
}

/*
  STYLES
*/
const Label = styled.div`
  overflow: hidden;
`

export const Name = styled.div`
  font-size: 12px;
  font-weight: ${fonts.weight.medium};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const Categories = styled(Name)`
  color: ${colors.translucentSecondary50};
  font-size: 11px;
  font-weight: ${fonts.weight.normal};
`

export const TopPartyIcon = styled(PartyIcon)`
  ${ColorIconStyled} {
    font-size: 8px;
  }
`

const AmountDelta = styled(DimensionTotalAmountWithDelta)`
  text-align: right;
  font-size: 10px;

  ${Amount} {
    font-size: 12px;
    font-weight: ${fonts.weight.normal};
  }
`

/*
  COMPONENTS
*/

export const TopPartiesList = React.memo<TopPartiesListProps>(
  ({ className, partySummaries, loading, padding = 10 }) => {
    const { history } = useRouter()
    const stopPropagation = useStopClickPropagation()
    const generatePath = useFrontendPathGenerator()
    const intervalOrigin = useReportComponentIntervalOrigin(12)
    const { isSharingContextActive } = useSession<FrontendSession>()

    const goToPartyDetails = React.useCallback(
      (data: PartyTransactionSummary, e: React.MouseEvent) => {
        stopPropagation(e)
        if (!data.party.roles) {
          TrackJS?.track(`No party role for ${data.party.name}`)
        }

        const partyRole = data.party.roles?.[0]

        history.push(
          generatePath(routes.partyDetails, {
            partyId: data.party.id,
            partyRole: FrontendPartyRole.roleURLKey(partyRole),
            ...intervalOrigin,
          })
        )
      },
      [generatePath, history, intervalOrigin, stopPropagation]
    )
    const onClick = isSharingContextActive ? undefined : goToPartyDetails

    return (
      <TopList
        className={className}
        padding={padding}
        data={partySummaries}
        loading={loading}
        activeIndex={undefined}
        IconComponent={Icon}
        LabelComponent={PartyLabel}
        SummaryComponent={PartySummary}
        onClick={onClick}
      />
    )
  }
)

const Icon: React.FC<SummaryListData<PartyTransactionSummary>> = ({ data }) => {
  if (!data) return null
  return <TopPartyIcon size={IconSize.Small} party={data.party} />
}

const PartyLabel: React.FC<SummaryListData<PartyTransactionSummary>> = ({ data }) => {
  const { textElementRef, isTextOverflowing } = useTextIsOverflowing<HTMLDivElement>()
  if (!data) return <LoadingBlock width="100px" height="30px" />

  const { name } = data.party
  const categoryNames = data.categoryNames.slice().sort().join(", ")
  return (
    <Label>
      <DigitsTooltip
        css=" overflow: hidden"
        content={<div>{name}</div>}
        disabled={!isTextOverflowing}
      >
        <Name ref={textElementRef}>{name}</Name>
      </DigitsTooltip>
      <Categories>{categoryNames}</Categories>
    </Label>
  )
}

const PartySummary: React.FC<SummaryListData<PartyTransactionSummary>> = ({ data }) => {
  if (!data) return null

  const { summary } = data
  return <AmountDelta summary={summary} allowDeltaToggle />
}
