/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import { styled } from "styled-components"

const linkStyles = `
  font-weight: ${fonts.weight.heavy};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
  }
`

const A = styled.a`
  ${linkStyles};
`

const Button = styled.button`
  all: unset;
  ${linkStyles};
  transition: color 175ms ease;

  &:disabled {
    pointer-events: none;
    color: ${colors.secondary60};
  }
`

export const Anchor: React.FC<
  React.AnchorHTMLAttributes<HTMLAnchorElement> & { className?: string; popup?: boolean }
> = ({ popup, ...rest }) => {
  if (popup) {
    return <A target="_blank" rel="noopener noreferrer" {...rest} />
  }

  return <A {...rest} />
}

/**
 * A button styled like a link
 */
export const AnchorButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> & { className?: string }
> = (props) => <Button {...props} />
