import { type BillingPrice, BillingProductName } from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import numberHelper, { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import stringHelper from "@digits-shared/helpers/stringHelper"
import { Subscription } from "src/frontend/components/Onboard/PLG/Context/usePlgOnboarding"

export function prettyPrice(price: BillingPrice) {
  return numberHelper.currency(price.amount, {
    style: CurrencyStyle.Aggregation,
  })
}

export function displayName(name: BillingProductName | Subscription) {
  switch (name) {
    case Subscription.AIBookkeeping:
    case BillingProductName.AIBookkeeping:
      return "AI Bookkeeping"
    case BillingProductName.FounderFinance:
    case BillingProductName.Free:
    case BillingProductName.FreeForever:
    case BillingProductName.Growth:
    case BillingProductName.Premium:
      return stringHelper.camelCaseToSpaces(name)

    case Subscription.AccountingTaxes:
      return "Accounting & Taxes"
    case Subscription.MonthlyAccounting:
      return "Monthly Accounting"
    case Subscription.TaxReadyBooks:
      return "Tax-Ready Books"
    case BillingProductName.UnknownName:
    default:
      return ""
  }
}

const SECONDS_PER_DAY = 86_400 // 60 seconds/min * 60 min/hr * 24 hr/day

export function daysUntil(unixTimestamp: number): number {
  const now = dateTimeHelper.unixNowSeconds()
  const remainingSeconds = unixTimestamp - now
  const remainingDays = Math.ceil(remainingSeconds / SECONDS_PER_DAY)
  return remainingDays > 0 ? remainingDays : 0
}

export default {
  daysUntil,
  displayName,
  prettyPrice,
}
