import * as React from "react"

export interface PageScrollContextProps<T extends HTMLElement = HTMLElement> {
  scrollElement: React.RefObject<T | null>
}

export const PageScrollContext = React.createContext<PageScrollContextProps>({
  scrollElement: React.createRef(),
})

export function useBuildPageScrollContext<T extends HTMLElement = HTMLDivElement>() {
  // Provide a scroll context so infinite scrolling works.
  const scrollElement = React.useRef<T>(null)
  const pageScrollContext = React.useMemo<PageScrollContextProps<T>>(() => ({ scrollElement }), [])

  return { scrollElement, pageScrollContext }
}
