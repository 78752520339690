import * as React from "react"
import { Link } from "react-router-dom"
import { type EntityCategory, type EntityParty } from "@digits-graphql/frontend/graphql-bearer"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { usePopoverMutexContext } from "@digits-shared/components/UI/Elements/PopUp/PopOverMutexContext"
import { IconSize } from "@digits-shared/components/UI/Icons/Icon"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { MAIN_CONTENT_BACKGROUND } from "src/frontend/components/OS/Applications/Reports/Report/Components/Shared/DetailsPopOver/DetailsPopUp"
import { PopOverHeaderButtons } from "src/frontend/components/OS/Applications/Reports/Report/Components/Shared/DetailsPopOver/PopOverHeaderButtons"
import { useReportEntityLink } from "src/frontend/components/OS/Applications/Reports/Report/hooks/useReportEntityLink"
import { SVGIconComponent } from "src/shared/components/Icons/SVGIcon"
import { PartyIcon } from "src/shared/components/PartyHover/PartyIcon"

/*
  STYLES
*/

const Container = styled.div`
  border-radius: ${borders.radius.highlight}px ${borders.radius.highlight}px 0 0;
  background: ${MAIN_CONTENT_BACKGROUND};
  display: flex;
  align-items: center;
  padding: 18px 20px 12px;
  position: relative;
`

const NameContainer = styled.div`
  flex: 1;
  overflow: hidden;
`

const NameLink = styled(Link)<{ disabled: boolean }>`
  font-size: 16px;
  font-weight: ${fonts.weight.heavy};
  white-space: nowrap;
  display: flex;
  align-items: center;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "")};

  &:hover {
    text-decoration: underline;
  }
`

const CategoryIcon = styled(SVGIconComponent)`
  height: 15px;
  width: 15px;
  min-width: 15px;
  margin-right: 10px;
  ${svgPathStyles(colors.secondary, 1.5)};
`

/*
  INTERFACES
*/

/*
  COMPONENTS
*/

export const CategoryTitle: React.FC<{
  category: EntityCategory
}> = ({ category }) => {
  const path = useReportEntityLink(category)
  const { clearActivePopOver } = usePopoverMutexContext()

  return (
    <Container>
      <NameContainer>
        <NameLink key="current" to={path ?? ""} disabled={!path} onClick={clearActivePopOver}>
          <CategoryIcon subjectDisplayKey={category.displayKey || category.name} />
          <div css="overflow: hidden;text-overflow: ellipsis;">{category.name}</div>
        </NameLink>
      </NameContainer>
      <PopOverHeaderButtons linkObject={category} />
    </Container>
  )
}

export const PartyTitle: React.FC<{ party: EntityParty }> = ({ party }) => {
  const path = useReportEntityLink(party)
  const { clearActivePopOver } = usePopoverMutexContext()

  return (
    <Container>
      <NameContainer>
        <NameLink key="current" to={path ?? ""} disabled={!path} onClick={clearActivePopOver}>
          <PartyIcon size={IconSize.Medium} party={party} css="margin-right: 10px;" />
          {party.name}
        </NameLink>
      </NameContainer>
      <PopOverHeaderButtons linkObject={party} />
    </Container>
  )
}
