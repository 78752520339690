import { Link } from "react-router-dom"
import { svgIconStyles, svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgDownload01 } from "@digits-shared/components/SVGIcons/line/Download01.svg"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { ReportPackageDocumentDownloader } from "src/frontend/components/OS/Applications/Reports/Report/Downloader/Downloader"

export const REPORT_PACKAGE_ITEM_BORDER_RADIUS = 20

/*
  STYLES
*/

export const ReportPackageLink = styled(Link)<{ $disabled?: boolean }>`
  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
    `}
`

const PACKAGE_DOCUMENT_STYLES = css`
  position: relative;
  border: 0.5px solid ${colors.transparent};
  border-radius: 50vh;
  font-weight: ${fonts.weight.medium};
  font-size: 12px;
  line-height: 11px;
  display: flex;
  align-items: center;
  color: ${colors.translucentSecondary80};
  padding: 3px 0;
  word-break: break-word;
  cursor: pointer;

  &:hover {
    color: ${colors.secondary};
  }
`

export const PackageDetails = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: -webkit-fill-available;
`

export const PackageDetailsMask = styled.div`
  display: inherit;
  flex-direction: inherit;
  gap: inherit;
  padding: 10px;
  border-radius: 4px;
  backdrop-filter: blur(30px);
`

export const PackageDocument = styled.div`
  ${PACKAGE_DOCUMENT_STYLES}
`

export const PackageDocumentLink = styled(Link)`
  ${PACKAGE_DOCUMENT_STYLES}
`

export const PackageDocumentName = styled.div`
  flex: 1;
  font-size: 10px;
  line-height: 11px;
`

const PACKAGE_DOCUMENT_ICON_STYLES = css`
  ${svgPathStyles(colors.secondary, 1.5)};
  opacity: 0;
  cursor: pointer;

  ${PackageDocument}:hover &, ${PackageDocumentLink}:hover & {
    opacity: 1;
  }
`

export const PackageDocumentDownloadIcon = styled(SvgDownload01)`
  width: 11px;
  height: 11px;
  ${PACKAGE_DOCUMENT_ICON_STYLES};
`

export const Downloader = styled(ReportPackageDocumentDownloader)`
  display: flex;
  width: 100%;
`

export const PackageDocumentDownloadContainer = styled.div<{ isDisabled: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      ${PackageDocument} {
        pointer-events: none;
      }

      ${PackageDocumentDownloadIcon} {
        ${svgIconStyles(colors.regentGray)};
      }
    `}
`

export const PackageTitlePeriod = styled(ReportPackageLink)`
  font-weight: ${fonts.weight.heavy};
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const PackageSubtitle = styled.div`
  font-weight: ${fonts.weight.normal};
  font-size: 16px;
  line-height: 25px;
`

export const PackagePreparer = styled.div`
  font-weight: ${fonts.weight.medium};
  font-size: 12px;
  line-height: 16px;
  opacity: 0.7;
`

export const ReportPackageItemContainer = styled.div<{
  coverSrc?: string
  disabled?: boolean
  ghosted?: boolean
}>`
  container-type: inline-size;
  container-name: reports-grid-item;

  position: relative;
  display: flex;
  flex-direction: column;
  background: ${colors.translucentWhite60};
  color: ${colors.white};
  border-radius: 8px;
  height: 300px;
  padding: 12px 12px 18px;

  &:hover {
    box-shadow: 0 0 24px 0 rgba(125, 125, 205, 0.39);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: lightgray 50% / cover no-repeat;
    backdrop-filter: blur(40px);

    ${({ coverSrc, ghosted }) =>
      coverSrc &&
      css`
        background-image: url("${coverSrc}");

        ${ghosted &&
        css`
          opacity: 0.7;
        `}
      `}
  }
`
