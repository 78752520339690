import * as React from "react"
import { DeltaChevron, DeltaContainer } from "@digits-shared/components/UI/Elements/Delta"
import { Amount } from "@digits-shared/components/UI/Table/Content"
import { DigitsTooltip } from "@digits-shared/DesignSystem/Tooltip"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import {
  Categories,
  TopPartiesList,
} from "src/frontend/components/OS/Applications/Reports/Report/Viewer/Layout/Shared/TopPartiesList"
import { ComponentHeaderTitle } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { useComponentTitle } from "src/frontend/components/Shared/Layout/Components/useComponentTitle"
import { useConfigOriginOverride } from "src/frontend/components/Shared/Layout/hooks/useConfigOriginOverride"
import { type TopEntitiesProps } from "src/frontend/components/Shared/Portals/Components/Lists/Shared"
import { useTopPartiesLiveData } from "src/frontend/components/Shared/Portals/hooks/useTopPartiesLiveData"
import { useTextIsOverflowing } from "src/frontend/hooks/useTextIsOverflowing"
import { type PartyTransactionSummary } from "src/frontend/types"

const StyledTopPartiesList = styled(TopPartiesList)`
  ${Categories}, ${Amount}, ${DeltaContainer} {
    color: ${colors.secondary50};

    ${DeltaChevron} {
      border-bottom-color: ${colors.secondary50};
      border-top-color: ${colors.secondary50};
    }
  }
`

/*
  COMPONENTS
*/

export const TopParties: React.FC<TopEntitiesProps> = ({ component, componentSize }) => {
  const config = useConfigOriginOverride(component.config.topEntities, 1)
  const { textElementRef, isTextOverflowing } = useTextIsOverflowing<HTMLDivElement>()
  const { title, periodName } = useComponentTitle(component)

  const { data, loading } = useTopPartiesLiveData(config, component.config.type)

  const partySummaries: PartyTransactionSummary[] | undefined = React.useMemo(
    () =>
      data?.entities.party.map((p) => ({
        party: p.partyObject,
        summary: p.summary,
        categoryNames: p.by.category.map((c) => c.categoryObject.name),
      })),
    [data]
  )

  return (
    <>
      <DigitsTooltip content={<div>{title}</div>} disabled={!isTextOverflowing}>
        <ComponentHeaderTitle
          ref={textElementRef}
          title={title}
          componentSize={componentSize}
          periodName={periodName}
        />
      </DigitsTooltip>
      <StyledTopPartiesList padding={10} partySummaries={partySummaries} loading={loading} />
    </>
  )
}
